export const borneDefault = [{
    value: "rouge",//valeur de l'element
    x: 705,//coordonnée x de point sur l'image Girlduino (ici le point rouge à coté du robot)
    y: 167//coordonnée y de point sur l'image Girlduino
  },
  {
    value: "noir",
    x: 705,
    y: 204
  },
  {
    value: "bleu",
    x: 705,
    y: 240
  },
  {
    value: "jaune",
    x: 705,
    y: 277
  },
  {
    value: "vert",
    x: 705,
    y: 313
  }
  ]

  export const borne2Default = [{
    value: "AREF",//valeur de l'element
    x: 282,//coordonnée x de point sur l'image Girlduino
    y: 29////coordonnée y de point sur l'image Girlduino
  },
  {
    value: "13",
    x: 326,
    y: 29
  },
  {
    value: "12",
    x: 348,
    y: 29
  },
  {
    value: "~11",
    x: 370,
    y: 29
  },
  {
    value: "~10",
    x: 392,
    y: 29
  },
  {
    value: "~9",
    x: 414,
    y: 29
  },
  {
    value: "8",
    x: 436,
    y: 29
  },
  {
    value: "7",
    x: 473,
    y: 29
  },
  {
    value: "~6",
    x: 495,
    y: 29
  },
  {
    value: "~5",
    x: 517,
    y: 29
  },
  {
    value: "4",
    x: 539,
    y: 29
  },
  {
    value: "~3",
    x: 561,
    y: 29
  },
  {
    value: "2",
    x: 584,
    y: 29
  },
  {
    value: "1<TX",
    x: 606,
    y: 29
  },
  {
    value: "O>RX",
    x: 628,
    y: 29
  },
  {
    value: "IOREF",
    x: 340,
    y: 450
  },
  {
    value: "RESET",
    x: 362,
    y: 450
  },
  {
    value: "3.3V",
    x: 384,
    y: 450
  },
  {
    value: "5V",
    x: 406,
    y: 450
  },
  {
    value: "GND",
    x: 428,
    y: 450
  },
  {
    value: "VIN",
    x: 472,
    y: 450
  },
  {
    value: "A0",
    x: 517,
    y: 450
  },
  {
    value: "A1",
    x: 539,
    y: 450
  },
  {
    value: "A2",
    x: 561,
    y: 450
  },
  {
    value: "A3",
    x: 583,
    y: 450
  },
  {
    value: "A4",
    x: 605,
    y: 450
  },
  {
    value: "A5",
    x: 627,
    y: 450
  },
  ]