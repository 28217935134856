import { Box, TextField } from '@mui/material';
import * as React from 'react';
import { Typography } from '@mui/material';
import { reponseTerminal } from '../../data/networkdata';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function TerminalDialog() {
  const navigate = useNavigate();
  let [index, setIndex] = React.useState(0);

  //Gère les commandes tapés
  let res = (e) => {
    let answer = e.target.value;
    if (e.keyCode === 13) {
      //Quand on fini le dialogue avec Dora, l'étape suivante démarre
      if (index >= 2) {
        if (answer.toLowerCase() === 'ok') {
          Cookies.set("network_state", 1, { path: '/' });
          navigate("/network/1");
          return
        }
      }

      //Cherche si la commande est reconnu dans le tableau reponseTerminal
      for (let element in reponseTerminal[index].answerAccepted) {
        if (answer === reponseTerminal[index].answerAccepted[element]) {
          //La commande est reconnue, mise à jour d'index et envoie réponse stockée dans reponseTerminal
          document.getElementById("erreur").style.display = "none";
          setIndex(index + 1);
          answer = "";
          document.getElementById("reponse").value = "";
          document.getElementById("terminal").style.display = "none"
          //Fait disparaitre et raparaitre les éléments pour simuler un vrai termianl
          setTimeout(function () { document.getElementById("terminal").style.display = "block" }, 200);
          return;
        }
      }
      //La réponse n'est pas reconnu, renvoie le mesage d'erreur
      document.getElementById("reponseEtErreur").style.display = "none";
      //Fait disparaitre et raparaitre les éléments pour simuler un vrai termianl
      setTimeout(function () { document.getElementById("reponseEtErreur").style.display = "block" }, 200);
      document.getElementById("erreur").style.display = "block";
      document.getElementById("reponse").value = "";
      answer = "";
    }
  }

  return (
    <>
      <Box sx={{ backgroundColor: 'black', width: '100%', height: '200px', overflowY: 'auto' }}>
        <Typography paddingLeft='15px' color="white">Terminal  - Connecté en tant que @Greenternet-console</Typography>
        <Box id="terminal">
          <Box margin="10px">
            <Typography id="reponseDora" color="white">{"> "}{reponseTerminal[index].text}</Typography>
            <Box id="reponseEtErreur">
              <Typography id="erreur" color="white" display="none" marginTop="10px">{"> "} La commande n'est pas reconnue</Typography>
              <Box sx={{ display: "flex" }}>
                <Typography color="white" width='2%' marginTop="30px">{"> "}</Typography>
                <TextField
                  id="reponse"
                  variant="filled"
                  color="black"
                  sx={{ margin: '5px', backgroundColor: 'black', input: { color: "white" }, width: '98%' }}
                  onKeyDown={res}
                  autoComplete='off'></TextField>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}