import * as React from "react";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Dialog, Slide, Typography } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


//Dialogue de fin qui pop + easter egg
export default function EndTimerDialog(props) {
    const [rick, setRick] = React.useState(false);
    const [click, setClick] = React.useState(42);

    const handleClickParallax = () => {
      if (click <= 0) {
        setRick(true); 
      } else {
        setClick(click-1);
      }
    }

    const handleCloseDialog = () => {
      props.setOpen(false);
    }

    return (
        <Dialog
        fullScreen
        open={props.open}
        TransitionComponent={Transition}
      >
        <Card sx={{maxWidth: "80%", margin: "auto"}}>
          <CardActionArea>
            {rick ? 
            <CardMedia
              loop
              component="video"
              autoPlay
              muted
              image="/videos/rick-roll.mp4"
              alt="Rick Roll"
            /> : 
            <CardMedia
              component="img"
              onClick={handleClickParallax}
              image="/img/parallaxe-title.png"
              alt="Parallaxe"
            /> }

          <CardContent>
            <Typography variant="h4">
              Temps écoulé ! Vous avez perdu !
            </Typography>
          </CardContent>
          </CardActionArea>
          <CardActions>
            <Button variant="contained" sx={{
            background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
            }}
            onClick={handleCloseDialog}
            >
              Continuer à jouer...
            </Button>
          </CardActions>
        </Card>
      </Dialog>
      
      );
}