export const hintData = [
    {
        id: "network",
        name: 'Administration Réseau',
        indexChallenge: 0,
        nbStepMax: 2,
        parties: [
            [{
                title: 'Indice',
                hintForStep: 1,
                content: "“Utilisez la carte pour identifier quels chemins emprunter pour atteindre les allié-es 1 et assurez-vous, à l’aide du terminal et de la ligne de commande “status adresse-ip” que les serveurs par lesquels vous pensez passer ne sont pas infectés. Attention, il ne faut pas mettre les crochets dans la ligne de commande !”"
            },
            {
                title: 'Indice',
                hintForStep: 1,
                content: '“Connectez-nous au premier serveur du chemin qui nous permettra de relier les allié-es 1. Etablissez ensuite la connexion entre ce serveur et celui d’après. Finissez pas relier le dernier serveur aux allié-es 1. Finissez par utiliser la ligne de commande “ping” + adresse-ip-des-allié-es-1 pour les contacter.”'
            },
            {
                title: 'Indice',
                hintForStep: 1,
                content: '“Il n’est pas nécessaire de contacter les deux allié-es. Pour se connecter aux allié-es 1 il faut passer par S3-S4-Allié-es 1. Puis faire la commande "ping 34.76.60.69" pour valider la connexion.”'
            }],
        ],
        solution: {
            title: 'Solution',
            hintForStep: 2,
            content: '“La solution/Le code de communication est : 4C”'
        }
    },{
        id: "social",
        name: 'Communication Digitale',
        indexChallenge: 1,
        nbStepMax: 4,
        parties: [
            {
                title: 'Indice',
                hintForStep: 0,
                content: "“Pensez à bien regarder vos messages.”"
            },
            [{
                title: 'Indice',
                hintForStep: 1,
                content: "“Le bot a posté des messages avec un hashtag qui incite les gens à faire quelque chose. Connectez vous en tant qu'administrateur pour régler cela.”"
            }, {
                title: 'Indice',
                hintForStep: 1,
                content: "“L'utilisateur pépé_mayo semble avoir un comportement étrange, il incite les gens à faire quelque chose.”"
            }],
            {
                title: 'Indice',
                hintForStep: 2,
                content: '“Le réseau social Knooter demande aux internautes d’avoir un comportement responsable. Publiez intelligemment, avec une image plutôt légère, pour éviter de polluer. (1 Mo = 1 000 000 octets, 1 Ko = 1000 octets)”'
            },
            {
                title: 'Indice',
                hintForStep: 3,
                content: "“Pensez à bien regarder vos messages.”"
            }
        ],
        solution: {
            title: 'Solution',
            hintForStep: 4,
            content: '“Combien de réactions suscite votre publication ? C’est le nombre de personnes qui pourront vous aider à anéantir le virus !” La solution est : 2340'
        }
    },
    {
        id: "bdd",
        name: 'Base de données et Data',
        indexChallenge: 2,
        nbStepMax: 2,
        parties: [
            [{
                title: 'Indice',
                hintForStep: 1,
                content: "“Vous n’avez pas besoin des informations qui parlent des serveurs sains, choisissez un graphique qui ne les affiche pas.”"
            },
            {
                title: 'Indice',
                hintForStep: 1,
                content: '“Reportez les données du graphique sur la table de contrôle (launchpad) pour voir clairement sur quel créneau horaire tous les serveurs infectés du monde sont allumés.”'
            }, {
                title: 'Indice',
                hintForStep: 1,
                content: '“Envoyez à Marie le créneau horaire sur lequel tous les serveurs sont allumés en appuyant sur Valider la sélection”'
            }]
        ],
        solution: {
            title: 'Solution',
            hintForStep: 2,
            content: 'La solution est : 3AM'
        }
    },
    {
        id: "hardware",
        name: "HARDWARE",
        indexChallenge: 3,
        nbStepMax: 3,
        parties: [
            [{
                title: 'Indice',
                hintForStep: 1,
                content: "“Pour démarrer le robot, regarder la notice, il faut tester tous les branchements, ne pas oublier d'appuyer sur le bouton RESET pour valider le branchement”"
            }, {
                title: 'Indice',
                hintForStep: 1,
                content: '“Essayez le branchement B”'
            },]
        ],
        solution: {
            title: 'Solution',
            hintForStep: 3,
            content: '“Le caractère d’encodage à retenir est celui tracé par le robot.” La solution est : !'
        }
    },
    {//Chaque objet est un challenge
        id: "code",//id de challenge égale au nom donnée dans le fichier challenges.js
        name: "Code et programmation",//Nom/Label de l'énigme  qui est afficher
        indexChallenge: 4,//Index du challenge dans le tableau challenges.js
        nbStepMax: 5,//Nombre d'étape totale dans l'énigme
        parties: [//dans parties on retrouve les indices simple et speciaux
            {
                title: 'Indice',//titre de au dessus de l'indice
                hintForStep: 1,//index de l'étapes à laquel l'indice peut etre débloqué
                content: "“Certains objets nécessitent le travail de développeuses et développeurs, d’autres non. Triez-les entre “Code” et “Pas code” pour faire avancer la jauge de scan et passer à la suite.”"//L'information de l'indice
            },
            [{//Si deux indices sont pour la meme etapes alors mettez les dans un tableau et mettre les memes valeurs pour 'hintForStep'
                title: 'Indice',
                hintForStep: 3,
                content: '“Si vous ne vous souvenez pas de tous les caractères donnés par Marjorie dans la vidéo, vous pouvez relancer la vidéo en cliquant sur son aperçu en bas à gauche.”'
            },
            {
                title: 'Indice',
                hintForStep: 3,
                content: '“Sur l’interface, vous devez entrer un caractère dans un champ de saisie pour qu’on exécute le code affiché au-dessus avec ce caractère. Tapez une lettre dans le champ de saisie en bas de l’écran, puis testez-le en cliquant sur le bouton, afin de trouver quel est le caractère qui permettra de résoudre l’énigme.”'
            }],

        ],
        solution: {//l'unique solution de l'énigme
            title: 'Solution',//titre de au dessus de la solution
            hintForStep: 5,//index de l'étapes à laquel la solution peut etre débloqué
            content: '“Le caractère trouvé correspond à un langage. Observer les tableaux pour trouver lequel, et retenez-le.”  La solution est : Python'//L'information de la solution
        }
    },
    
    
    
    
]