import Typography from '@mui/material/Typography';
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { LinearProgress, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import HelpIcon from '@mui/icons-material/Help';

import { objCodePasCode } from '../../data/codeprogradata';
import { Link } from 'react-router-dom';

let progression = 0;

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function EnigmeCodeProgra1({ onEnigmeResolved }) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([0, 1, 2, 3, 4, 5, 6]);
  const [right, setRight] = React.useState([7, 8, 9, 10, 11, 12, 13]);
  const [success, setSuccess] = React.useState(false);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    let copyRight = [...right.concat(left)];
    setRight(copyRight);
    setLeft([]);
    handleChangeUpdate([], copyRight);
  };

  const handleCheckedRight = () => {
    let copyLeft = [...not(left, leftChecked)];
    let copyRight = [...right.concat(leftChecked)];
    setRight(copyRight);
    setLeft(copyLeft);
    setChecked(not(checked, leftChecked));
    handleChangeUpdate(copyLeft, copyRight);
  };

  const handleCheckedLeft = () => {
    let copyLeft = [...left.concat(rightChecked)];
    let copyRight = [...not(right, rightChecked)];
    setLeft(copyLeft);
    setRight(copyRight);
    setChecked(not(checked, rightChecked));
    handleChangeUpdate(copyLeft, copyRight);
  };

  const handleAllLeft = () => {
    let copyLeft = [...left.concat(right)];
    setLeft(copyLeft);
    setRight([]);
    handleChangeUpdate(copyLeft, []);
  };

  const customList = (items) => (
    <Paper sx={{ width: 350, height: 380, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={objCodePasCode[value].id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <img src={objCodePasCode[value].logo} alt={objCodePasCode[value].label} style={{width: '24px', height: '24px'}} />
              <ListItemText sx={{pl:"10px"}} id={labelId} primary={objCodePasCode[value].label} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  const handleChangeUpdate = (leftTab, rightTab) => {
    let nb_elem_correctly_checked = 0;
    objCodePasCode.forEach((item, index) => {
      if ((rightTab.includes(index) && item.ResponseValue) || 
          (leftTab.includes(index) && !item.ResponseValue)) {
        nb_elem_correctly_checked += 1;
      }
    });
    progression = (nb_elem_correctly_checked / objCodePasCode.length) * 100;
    const newIsResolved = progression === 100;
    setSuccess(newIsResolved);
    
    if (newIsResolved) {
      onEnigmeResolved();
    }
  };

  const isMobileBreakpoint = useMediaQuery('(max-width: 841px)');
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Box
        display={isMobileBreakpoint ? 'none' : 'flex'}
        justifyContent="center"
        alignItems="center"
        marginTop={'20px'}
        marginBottom={'10px'}
        textAlign={'center'}
      >
        «Identifiez les objets remplis de code (car le virus les a tous infectés)»
      </Box>
      {isMobileBreakpoint && (
        <div className='helpWrapper'>
          <Button onClick={handleOpen} sx={{position: 'fixed', zIndex: 99}}>
            <HelpIcon sx={{fontSize: '45px', color: '#6b22e4'}} />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                «Identifiez les objets remplis de code (car le virus les a tous infectés)»
              </Typography>
            </Box>
          </Modal>
        </div>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={'30px'}
      >
        <FormControl component="fieldset" variant="standard">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={'10px'}
          >
            <Typography color={success ? "#4CAF50" : "#000000"} variant="p" textAlign='center'>
              {success ? "Bravo, vous avez réussi ! Vous pouvez passer à l'étape suivante." : ""}
            </Typography>
          </Box>
          {isMobileBreakpoint && (
            <LinearProgress sx={{mt: '0px', p: '5px',}} color={success ? "success" : "primary"} variant="determinate" value={progression} />
          )}
         
          <FormGroup sx={{marginTop: '30px'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" flexDirection={isMobileBreakpoint ? 'column' : 'initial'}>
              <Grid item>
                <Typography variant='h5' display={'flex'} flexDirection={'column'} alignItems={'center'}>Pas Code</Typography>
                {customList(left)}
              </Grid>
              {isMobileBreakpoint ? (
                <Grid item>
                  <Grid container margin={'30px 0px'} direction="row" justifyContent={'center'} alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ︾
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      ﹀
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      ︿
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ︽
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Grid container direction="column" alignItems="center">
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllRight}
                      disabled={left.length === 0}
                      aria-label="move all right"
                    >
                      ≫
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedRight}
                      disabled={leftChecked.length === 0}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleCheckedLeft}
                      disabled={rightChecked.length === 0}
                      aria-label="move selected left"
                    >
                      &lt;
                    </Button>
                    <Button
                      sx={{ my: 0.5 }}
                      variant="outlined"
                      size="small"
                      onClick={handleAllLeft}
                      disabled={right.length === 0}
                      aria-label="move all left"
                    >
                      ≪
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Typography variant='h5' display={'flex'} flexDirection={'column'} alignItems={'center'}>Code</Typography>
                {customList(right)}
              </Grid>
            </Grid>
          </FormGroup>
          {!isMobileBreakpoint && (
            <LinearProgress sx={{mt: '10px', p: '5px',}} color={success ? "success" : "primary"} variant="determinate" value={progression} />
          )}
        </FormControl>
      </Box>
      {success && (
        <Link to="/code/2" style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" sx={{ background: 'linear-gradient(45deg, #6B22E4, #AB9230)'}}>Suivant</Button>
        </Link>
        )}
    </>
  );
}