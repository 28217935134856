import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';

import KnootContext from "./KContext";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function Admin(props) {
    const [htag, setHtag] = React.useState("");
    const [pseudo, setPseudo] = React.useState("");
    const [iserrorhtag, setIsErrorHtag] = React.useState(false);
    const [iserrorpseudo, setIsErrorPseudo] = React.useState(false);

    const { checkBan } = React.useContext(KnootContext);

    const handleClose = () => {
        props.setOpen(false);
    };

    const bannirHtag = () => {
        if (htag) {
            let hashtag = htag;
            if (!hashtag.startsWith("#")) {
              hashtag = "#" + hashtag;
            }
            checkBan(hashtag);
            setHtag("");
        } else {
            setIsErrorHtag(true);
        }
    }

    const bannirPseudo = () => {
        if (pseudo) {
            checkBan(pseudo);
            setPseudo("");
        } else {
            setIsErrorPseudo(true);
        }
    }



    return (
        <Box>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <div><img alt='logo' src="/img/tweet_img/logo-knooter.png" width="100px"></img></div>
                    <Stack direction='row' alignItem='center' spacing={1} justifyContent={"center"}>

                        <PersonIcon />
                        <Typography noWrap>
                            Marguerite
                        </Typography>
                    </Stack>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Typography sx={{margin: {xs: '25px 5px'}, textAlign: { xs: 'center'}}}>Bienvenu sur votre compte administarteur. D'ici vous pouvez bannir un utilisateur ou un hashtag qui pose problème.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField
                            id="reponse-textField"
                            label="Saisir le hashtag à bannir : "
                            variant="outlined"
                            style={{ maxWidth: '300px' }}
                            type="text"
                            error={iserrorhtag}
                            helperText={iserrorhtag ? "Le hashtag est inccorect" : ""}
                            value={htag}
                            onChange={(event) => {
                                setIsErrorHtag(false);
                                setHtag(event.target.value);
                            }}
                        />
                        <Button sx={{ backgroundColor: 'gray', marginLeft: '10px', marginTop: '5px', marginBottom: '30px' }}
                            variant="contained"
                            color="primary"
                            onClick={bannirHtag}
                        >
                            Bannir
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField
                            id="reponse-textField"
                            label="Saisir le pseudo à bannir : "
                            variant="outlined"
                            style={{ maxWidth: '300px' }}
                            error={iserrorpseudo}
                            helperText={iserrorpseudo ? "Le pseudo est inccorect" : ""}
                            type="text"
                            value={pseudo}
                            onChange={(event) => {
                                setIsErrorPseudo(false);
                                setPseudo(event.target.value);
                            }}
                        />
                        <Button sx={{ backgroundColor: 'gray', marginLeft: '10px', marginTop: '5px', marginBottom: '30px' }}
                            variant="contained"
                            color="primary"
                            onClick={bannirPseudo}
                        >
                            Bannir
                        </Button>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        </Box>
    );
}