//Importation des bibliotheques
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HubIcon from '@mui/icons-material/Hub';
import CellTowerIcon from '@mui/icons-material/CellTower';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import HardwareIcon from '@mui/icons-material/Hardware';
import CodeIcon from '@mui/icons-material/Code';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CopyrightIcon from '@mui/icons-material/Copyright';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import { useMediaQuery } from '@mui/material';

//Taille du menu
let drawerWidth = 240;

//CSS du bouton menu
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

//CSS de la Barre
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, colors }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  background: 'linear-gradient(45deg, #6B22E4 40%, #2ECD75 60%)'
}));

//CSS du titre
const Title = styled(Typography)(({ theme }) => ({
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem',
    textAlign: 'center'
  }
}));

//CSS du menu
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));





export default function PersistentDrawerLeft(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Clique sur les boutons de navigation
  const handleButtonClickInsideDrawer = (i) => {
    if (i === 0) {
      navigate('/hub')
    } else if (i === 1) {
      navigate('/network/' + props.stepid[0])
    } else if (i === 2) {
      navigate('/social/' + props.stepid[1])
    } else if (i === 3) {
      navigate('/bdd/' + props.stepid[2])
    } else if (i === 4) {
      navigate('/hardware/' + props.stepid[3])
    } else if (i === 5) {
      navigate('/code/' + props.stepid[4])
    } else if (i === 6) {
      navigate('/hint')
    } else if (i === 7) {
      navigate('/final')
    } else if (i === 8) {
      navigate('/LegalNotice')
    }
    handleDrawerClose();
  }

  //Icones des boutons de navigation
  const chooseIcon = (i) => {
    if (i === 0) {
      return <HubIcon />
    } else if (i === 1) {
      return <CellTowerIcon />
    } else if (i === 2) {
      return <PublicIcon />
    } else if (i === 3) {
      return <StorageIcon />
    } else if (i === 4) {
      return <HardwareIcon />
    } else if (i === 5) {
      return <CodeIcon />
    } else if (i === 6) {
      return <LightbulbIcon />
    } else if (i === 7) {
      return <CheckCircleIcon />
    } else if (i === 8) {
      return <CopyrightIcon />
    }
  }

  const reset = () => {
    clearInterval(props.intervalID);
    Object.keys(Cookies.get()).forEach(function (cookieName) {
        var neededAttributes = {
            path: '/',
        };
        Cookies.remove(cookieName, neededAttributes);
    });
    navigate('/');
    Cookies.set('EndGame', 0, { path: '/' });
    localStorage.clear();

    window.location.reload();
}
   
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  isMobileBreakpoint ? drawerWidth = '100%' : drawerWidth = 240;
  
  //Crédits utilisés 
  const hintcreditUse = Number(Cookies.get('hintcreditUse'));
  //Crédits donnés au moment X
  const hintCreditmaxGiveAtTime = Cookies.get('hintCreditmaxGiveAtTime');
  //Fin du jeu ?
  const endGame = parseInt(Cookies.get('EndGame'));

  return (
    <Box sx={{ display: 'flex', mb: '0px', zIndex: 10000 }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ml: '0px',mr: "0px", ...(open && { display: 'none' })}}
          >
          <MenuIcon />
          </IconButton>
          <Title variant='h4' className='titleBanner' textAlign={'center'}  sx={{ ml: 'auto' }}>
            {props.time + " " + props.title}
          </Title>
          <Button disabled={endGame === 1} variant="contained" startIcon={<LightbulbIcon />} color="secondary" sx={{color: 'white', backgroundColor: '#6B22E4' }} onClick={() => handleButtonClickInsideDrawer(6)}>
            {!isMobileBreakpoint && ('indice')} {isNaN(Number(hintCreditmaxGiveAtTime-hintcreditUse)) ? 0 : (hintCreditmaxGiveAtTime-hintcreditUse)}
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {endGame === 0 ? ['Hub', 'Administration Réseau', 'Communication Digitale', 'Base de données et Data', 'HARDWARE', 'Code et Programmation'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => {
                handleButtonClickInsideDrawer(index);
              }}>
                <ListItemIcon>
                  {chooseIcon(index)}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )): null}
        </List>
        {endGame === 0 ? <Divider /> : null}
        <List>
          {endGame === 0 ? ['Indices', 'Enigme Final'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => {
                handleButtonClickInsideDrawer(index + 6);
              }}>
                <ListItemIcon>
                  {chooseIcon(index + 6)}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )):  <Button
                    sx={{
                        display: 'flex',
                        margin: 'auto',
                        marginBottom: '15px',
                        background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                    }}
                    data-testid="reset-button"
                    variant="contained"
                    size="small"
                    onClick={reset}
                >
                    Rejouer
                </Button>
          }
        </List>
        <Divider />
        <ListItem key={'LegalNotice'} disablePadding>
              <ListItemButton onClick={() => {
                handleButtonClickInsideDrawer(2 + 6);
              }}>
                <ListItemIcon>
                  {chooseIcon(2 + 6)}
                </ListItemIcon>
                <ListItemText primary={'Mentions legales'} />
              </ListItemButton>
            </ListItem>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}