//Importations de bibliothèques
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { LinearProgress } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';

import { challenges } from '../data/challenges';
import Cookies from 'js-cookie';

import { hintData } from '../data/hintdata';
//Styles des éléments
const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//Fonction qui recrée à partir du Json hintdatajs un objet plus léger portant les informations des indices déjà débloqués ou pas
const initStateHint = (JsonHintData) => {
    //recrétion de la structure avec comme clé les id des hint à caché ou pas
    //idée: bouclé à traver l'objet JsonHintData
    let StateHint = {};
    for (let challenge in JsonHintData) {//boucle pour chaqu challenge
        let objChalleng = {}
        for (let elem in JsonHintData[challenge]) {//boucle sur chaque lement de chaque challenge
            if (elem === 'parties') {//si l'elemment que l'on a est une partie alors dedans il n'y a que les indices
                for (let indice in JsonHintData[challenge][elem]) {//boucle sur tous les indices/etapes
                    if (JsonHintData[challenge][elem][indice].length > 1) {//on detecte si l'indice est un indice spéciale (plusieur indice par étapes de jeux)
                        let objindiceSpecial = {}
                        let indexSpecialIndice = 0;
                        for (let indicespecial in JsonHintData[challenge][elem][indice]) {//pour chaque indice présent dans la même étape, enregistrer les informations : [états par défaut (non débloque), à quelles étapes de jeu de l'énigme est destiné cet indice, le nombre d'étapes maximum du challenge à la quel appartient l'indice]
                            objindiceSpecial[challenge + "_" + JsonHintData[challenge][elem][indice][indexSpecialIndice]["hintForStep"] + "_" + indexSpecialIndice + "_indiceSpecial_state"] = [0, JsonHintData[challenge][elem][indice][indicespecial]["hintForStep"], JsonHintData[challenge]['nbStepMax']];
                            indexSpecialIndice += 1;
                        }
                        objChalleng[challenge + "_" + JsonHintData[challenge][elem][indice][0]["hintForStep"] + "_indice_state"] = objindiceSpecial;//ajout de plusieur indice dans une étapes
                    } else {//pour chaque indice, enregistrer les informations : [états par défaut (non débloque), à quelles étapes de jeu de l'énigme est destiné cet indice, le nombre d'étapes maximum du challenge à la quel appartient l'indice]
                        objChalleng[challenge + "_" + JsonHintData[challenge][elem][indice]["hintForStep"] + "_indice_state"] = [0, JsonHintData[challenge][elem][indice]["hintForStep"], JsonHintData[challenge]['nbStepMax']];
                    }
                }
            } else if (elem === 'solution') {//si l'elemment que l'on a est une solution alors dedans il n'y a qu'un indice
                //pour la solution, enregistrer les informations : [états par défaut (non débloque), à quelles étapes de jeu de l'énigme est destiné cet indice, le nombre d'étapes maximum du challenge à la quel appartient l'indice]
                objChalleng[challenge + "_" + JsonHintData[challenge][elem]["hintForStep"] + "_solution_state"] = [0, JsonHintData[challenge][elem]["hintForStep"], JsonHintData[challenge]['nbStepMax']];
            }
        }
        StateHint[challenge + '_challenge'] = objChalleng;
    }
    return StateHint;
}

export default function NestedList() {
    //état des codes alertes
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);

    //Fonction d'ouverture des éléments alerte
    const handleClick = () => {
        setOpen(true);
        setOpen2(false);
        setOpen3(false);
    };

    const handleClick2 = () => {
        setOpen(false);
        setOpen2(true);
        setOpen3(false);
    };

    const handleClick3 = () => {
        setOpen(false);
        setOpen2(false);
        setOpen3(true);
    };

    //Fonction de fermeture des élements alerte
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen2(false);
    };

    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen3(false);
    };

    //Récupération des cookies pour connaitre l'état des crédits d'indice 
    let hintcreditUse = Number(Cookies.get('hintcreditUse'));//Nombre de crédits déjà utilisé
    let hintCreditmaxGiveAtTime = Cookies.get('hintCreditmaxGiveAtTime');//Nombre de crédits donné depuis le début de la partie

    //Fonction pour modifier l'état des indices débloque et le nombre de crédits d'indices utilisé
    const modifyCookieIndice = (indexChallenge, challengeCurrentStep, StateHintJSON) => {
        Cookies.set('hintcreditUse', (1 + Number(Cookies.get('hintcreditUse'))), { path: '/' })
        StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"][0] = 1
        Cookies.set('hint', JSON.stringify(StateHintJSON), { path: '/' })
    }

    //Fonction pour modifier l'état des indices specials débloque et le nombre de crédits d'indices utilisé
    const modifyCookieIndiceSpecial = (indexChallenge, challengeCurrentStep, index, StateHintJSON) => {
        Cookies.set('hintcreditUse', (1 + Number(Cookies.get('hintcreditUse'))), { path: '/' })
        StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"][indexChallenge + "_" + challengeCurrentStep + "_" + index + "_indiceSpecial_state"][0] = 1
        Cookies.set('hint', JSON.stringify(StateHintJSON), { path: '/' })
    }

    //Fonction pour modifier l'état des solutions débloque et le nombre de crédits d'indices utilisé
    const modifyCookieSolution = (indexChallenge, challengeCurrentStep, StateHintJSON) => {
        Cookies.set('hintcreditUse', (1 + Number(Cookies.get('hintcreditUse'))), { path: '/' })
        StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_solution_state"][0] = 1
        Cookies.set('hint', JSON.stringify(StateHintJSON), { path: '/' })
    }

    //Fonction pour débloquer un hint (vérification de crédit disponible, de l'existence d'indice à l'étape du jeu, identification de l'indice/indice-spécial/solution en fonction de l'avancement)
    const unlockHint = (event, indexChallenge, challengeCurrentStep, StateHintJSON, hintDataJSON) => {
        let allHintAtThisStepUnclocked = false;
        let hintHasJustBeenUnlocked = false
        if ((hintCreditmaxGiveAtTime - hintcreditUse) > 0) {//Si des crédits d'indice sont disponibles
            for (let i = 0; i <= hintDataJSON[indexChallenge].nbStepMax; i++) {//Boucle pour examiner parmi tous les indices lequel doit être unlock
                if (StateHintJSON[indexChallenge + "_challenge"].hasOwnProperty(indexChallenge + "_" + challengeCurrentStep + "_indice_state") || StateHintJSON[indexChallenge + "_challenge"].hasOwnProperty(indexChallenge + "_" + challengeCurrentStep + "_solution_state")) {//S'il existe un indice OU si c'est une solution
                    if (StateHintJSON[indexChallenge + "_challenge"].hasOwnProperty(indexChallenge + "_" + challengeCurrentStep + "_indice_state")) {//S'il existe un indice
                        if (StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"][1] === Number(challengeCurrentStep)) {//S'il existe un indice simple à cette étape
                            if (StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"][0] !== 1) {//Si l'indice ciblé n'est pas déjà  débloqué
                                //console.log("L'indice ciblé n'est pas déja débloqué")
                                modifyCookieIndice(indexChallenge, challengeCurrentStep, StateHintJSON)
                                hintHasJustBeenUnlocked = true
                            } else {//si l'indice ciblé est déja débloqué
                                allHintAtThisStepUnclocked = true;
                            }
                        } else {// si l'indice est un indice scpécial
                            //console.log("---- else")
                            //console.log("Indice scpécial found")
                            for (let index = 0; index < Object.keys(StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"]).length; index++) {//Boucle sur chaque indice spécial dans cette étape
                                let obj = StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_indice_state"][indexChallenge + "_" + challengeCurrentStep + "_" + index + "_indiceSpecial_state"]
                                if (obj[1] === Number(challengeCurrentStep)) {//S'il existe un indice spécial à cette étape
                                    if (!hintHasJustBeenUnlocked && obj[0] !== 1) {//Si l'indice spécial ciblé n'est pas déjà débloqué
                                        modifyCookieIndiceSpecial(indexChallenge, challengeCurrentStep, index, StateHintJSON);
                                        //console.log("unclocked et 'hintHasJustBeenUnlocked' passe à True")
                                        hintHasJustBeenUnlocked = true;
                                    } else {//Si l'indice spécial ciblé est déjà débloqué
                                        allHintAtThisStepUnclocked = true;
                                    }
                                }
                            }
                        }
                    } else if (StateHintJSON[indexChallenge + "_challenge"].hasOwnProperty(indexChallenge + "_" + challengeCurrentStep + "_solution_state")) {//Si c'est une solution
                        let obj = StateHintJSON[indexChallenge + "_challenge"][indexChallenge + "_" + challengeCurrentStep + "_solution_state"]
                        if (obj[1] === Number(challengeCurrentStep)) {//S'il existe une solution à cette étape
                            if (!hintHasJustBeenUnlocked && obj[0] !== 1) {//Si la solution ciblé n'est pas déja débloqué
                                modifyCookieSolution(indexChallenge, challengeCurrentStep, StateHintJSON)
                                //console.log("unclocked solution et 'hintHasJustBeenUnlocked' passe à True")
                                hintHasJustBeenUnlocked = true;
                            } else {//Si la solution ciblé est déja débloqué
                                allHintAtThisStepUnclocked = true;
                            }
                        }
                    }
                } else {//S'il n'existe pas d'indice à cette étape
                    if (!allHintAtThisStepUnclocked && !hintHasJustBeenUnlocked) {
                        //console.log("Il n'existe pas d'indice à cette étape")
                        handleClick()
                    }
                }
            }
        } else {//Si il n'y a plus de credits d'indice disponibles
            handleClick2()
            //console.log("Il n'y a plus de credits d'indice disponibles")
        }
        if (allHintAtThisStepUnclocked && !hintHasJustBeenUnlocked) {//L'indice que vous demandez est déjà débloqué ou il n'y en a plus d'autre pour cette étape (car vous avez tous débloqué) !
            handleClick3()
        }
        return true;
    }

    //----------------Gestion mémoire des indices avec un seul cookie
    //Cookies.remove('hint', { path: '/', domain: 'localhost' })//pour supprimer le cookie hint, le remettre à zero
    let StateHint = {}
    if (Cookies.get('hint') === undefined) {//Si le cookie portant l'information de l'état des indices débloqué n'existe pas, on le crée
        StateHint = initStateHint(hintData)//On crée un objet avec moins d'information inutiles / plus léger que l'on sauvegardera
        Cookies.set('hint', JSON.stringify(StateHint), { path: '/' })
        //console.log('Cookies inexistent, initialisation du cookie...')
    } else {//S'il existe on le charge
        StateHint = JSON.parse(Cookies.get('hint'))
        //console.log('Cookies trouvé, chargement du cookie...')
    }
    //console.log(StateHint)
    //-------------------------------------
    return (
        <>
            <Box sx={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}><Typography color="primary" variant='h5'>{(hintCreditmaxGiveAtTime - hintcreditUse) > 0 ? "Vous avez " + (hintCreditmaxGiveAtTime - hintcreditUse) + " crédit(s) d'indice à utiliser sur n'importe quelle énigme" : "Vous n'avez aucun crédit d'indice"}</Typography></Box>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 5 }} columns={{ md: 10 ,lg: 20 }} justifyContent="center" flexWrap="wrap" width='90%' margin='auto'  >
                    {hintData.map((challenge, indexChallenge) => (//pour chaque challenge existant on crée une colonne
                        <Grid paddingBottom="50px"  xs={20} sm={20} md={4} key={indexChallenge} id={indexChallenge + '_challenge'}>
                            <Item sx={{boxShadow: "6", minHeight: { md: '500px' }}}>
                                <Paper square>
                                    <Typography>{challenge.name}</Typography>
                                    <Typography>
                                        {Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') != null ? Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') : "0"}/{challenge.nbStepMax /*Si l'énigme n'a pas encore enregistré un avancement, ont choisi par défaut de dire qu'elle est à zéro*/}
                                    </Typography>
                                    <LinearProgress color="primary" variant="determinate" value={((Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') != null ? Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') : 0)/challenge.nbStepMax)*100} />
                                </Paper>
                                <React.Fragment>
                                    <CssBaseline />
                                    <List id={indexChallenge + '_list'}>
                                        {challenge.parties.map((indice, indexIndice) => (//Pour chaque étape
                                            <Box key={"" + (indexIndice)}>
                                                <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                                    Partie {indexIndice + 1}
                                                </ListSubheader>
                                                {indice.hasOwnProperty('title') === true //Si c'est un indice simple
                                                    ? (<Box key={"" + (indexIndice + indexChallenge)} id={indexChallenge + "_" + indice.hintForStep + "_indice_state"} sx={{ display: StateHint[indexChallenge + "_challenge"][indexChallenge + "_" + indice.hintForStep + "_indice_state"][0] ? 'auto' : 'none' }}>
                                                        <ListItem sx={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                            <ListItemAvatar>
                                                                <Avatar sx={{minWidth: '56px', minHeight: '56px'}} alt={"" + (1 + indexIndice)} src={"_"} />
                                                            </ListItemAvatar>
                                                            <ListItemText sx={{textAlign: 'center'}} primary={indice.title} secondary={indice.content} />
                                                        </ListItem>
                                                    </Box>)
                                                    : (indice.map((specialIndice, indexSpecialIndice) => ( //Si c'est un indice special simple
                                                        //Faire apparaitre ou disparaitre l'indice en fonction de son état
                                                        <Box key={"" + (indexIndice + indexChallenge + indexSpecialIndice)} id={indexChallenge + "_" + indice[indexSpecialIndice].hintForStep + "_" + indexSpecialIndice + "_indiceSpecial_state"} sx={{ display: StateHint[indexChallenge + "_challenge"][indexChallenge + "_" + indice[indexSpecialIndice].hintForStep + "_indice_state"][indexChallenge + "_" + indice[indexSpecialIndice].hintForStep + "_" + indexSpecialIndice + "_indiceSpecial_state"][0] ? 'auto' : 'none' }}>
                                                            <ListItem sx={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{minWidth: '56px', minHeight: '56px'}} alt={"" + (1 + indexIndice + indexSpecialIndice)} src={"_"} />
                                                                </ListItemAvatar>
                                                                <ListItemText sx={{textAlign: 'center'}} primary={specialIndice.title} secondary={specialIndice.content} />
                                                            </ListItem>
                                                        </Box>)))}
                                            </Box>
                                        ))}
                                        <Box>
                                            <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                                                Solution
                                            </ListSubheader>
                                            <Box id={indexChallenge + "_" + challenge.solution.hintForStep + "_solution_state"} sx={{ display: StateHint[indexChallenge + "_challenge"][indexChallenge + "_" + challenge.solution.hintForStep + "_solution_state"][0] ? 'auto' : 'none' }}>
                                                <ListItem sx={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={challenge.solution.title} src={"_"} />
                                                    </ListItemAvatar>
                                                    <ListItemText primary={""} secondary={challenge.solution.content} />
                                                </ListItem>
                                            </Box>
                                            <ListItem sx={{ mt: '30px' }}>
                                                <StyledFab data-testid={"Btn-unlock-hint-"+indexChallenge} color="secondary" aria-label="add" onClick={(e) => { unlockHint(e, indexChallenge, (Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') != null ? Cookies.get(challenges[challenge.indexChallenge].enigme + '_state') : 0), StateHint, hintData); return true; }}>
                                                    <AddIcon />
                                                </StyledFab>
                                            </ListItem>
                                        </Box>
                                    </List>
                                </React.Fragment>
                            </Item>
                        </Grid>
                    ))}
                </Grid>
            </Box >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    Il n'existe pas d'indice à cette étape de l'énigme !
                </Alert>
            </Snackbar>
            <Snackbar open={open2} autoHideDuration={6000} onClose={handleClose2}>
                <Alert onClose={handleClose2} data-testid={"alert-zero-credit-hint"} severity="error" sx={{ width: '100%' }}>
                    Vous n'avez aucun crédit d'indice à utiliser !
                </Alert>
            </Snackbar>
            <Snackbar open={open3} autoHideDuration={6000} onClose={handleClose3}>
                <Alert onClose={handleClose3} severity="info" sx={{ width: '100%' }}>
                    L'indice que vous demandez est déjà débloqué ou il n'y en a plus d'autre pour cette étape !
                </Alert>
            </Snackbar>
        </>
    );
}