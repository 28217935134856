export const authData = [
    {//Données des pages d'authentification de chaque énigme
        enigme: "network",//Nom de l'énigme ne question (meme nom que dans le fichier challenges.js)
        fullname: "Administration Réseau",//Label/Nom que l'on retrouve dans la page d'authentification
        user: "dora.raid@greenternet.com",//username / email déja prérempli
        //Indication quand on clique sur "Mot de passe oublié", une mini énigme pour deviner le mot de passe
        indication: "Un administrateur de réseaux est une personne qui s’occupe de la gestion et de l’entretien des réseaux informatique d’une entreprise ou d’une organisation.\n Cela signifie qu’il doit s’assurer que les ordinateurs et les autres appareils sont connectés entre eux et peuvent communiquer efficacement. \n Wireshark est un logiciel libre et gratuit qui analyse ce qui se passe sur le réseau informatique. \nDora à l'habitude d'utiliser le logiciel Wireshark pour le dépannage et l’analyse de réseaux informatique ou le développement de protocoles. \nIl se pourrait que le mot de passe soit le nom de son logiciel préféré.  \nLes mots de passe sont parfois tout en majuscules avec les O majuscules qui peuvent être remplacés par des 0 et les i par des 1.",
        password: "268e88fe9ec293450d721c76c9871261435ea6fafea5d251c541afbdb00feb93", //Mot de passe haché en sha256 ici c'est le hachage de "W1RESHARK"
        //Mot de passe en claire : W1RESHARK 
    },
    {
        enigme: "social",
        fullname: "Communication Digitale",
        user: "marguerite.mawasiliano@greenternet.com",
        indication: "La communication digitale est un métier qui consiste à utiliser les outils numériques pour transmettre des messages et des informations entre personnes. \nCela peut inclure l’utilisation de réseaux sociaux, tels que \"Facebook\", \"Twitter\", \"Amstramgram\", \"TikTok\" pour atteindre les gens et leur montrer une entreprise, une organisation; des idées ou des concepts. \nMarguerite a expliqué qu'elle préférait utiliser le réseau social TikTok pour faire la pub de Parallaxe 2050. \nIl se pourrait que le mot de passe soit le nom de son réseau social préféré. \nLes mots de passe sont parfois tout en majuscules avec les O majuscules qui peuvent être remplacés par des 0 et les i par des 1",
        password: "c5b9bc7ddfd9e1dac52a3bd7650b78d4adb30264378d0e09f777a78984987f30", //Mot de passe en claire : T1KT0K
    },
    {
        enigme: "bdd",
        fullname: "Science des Données",
        user: "marie.quetelet@greenternet.com",
        indication: "Marie a toujours aimé récolter des données pour faire des statistiques. Elle utilise son logiciel préféré : RStudio. \nGrâce à ce logiciel, elle peut utiliser facilement plein d'outils statistiques pour analyser les données. \nIl se pourrait que le mot de passe soit le nom de son logiciel préféré. \nLes mots de passe sont parfois tout en majuscules avec les O majuscules qui peuvent être remplacés par des 0 et les i par des 1",
        password: "3c8c084c3c5c6c436c73d1c055930a43d9533ae7dec77e966f30e4d6f43d5869", //Mot de passe en claire : RSTUD10
    },
    {
        enigme: "hardware",
        fullname: "Automatisme et Robotique",
        user: "leatitia.lunokhod@greenternet.com",
        indication: "Une carte graphique est un composant d'ordinateur dont le rôle est de produire une image affichable sur un écran.  \nLes deux principaux constructeurs de cartes graphiques sont Nvidia et AMD. \nLeatitia a expliqué qu'elle n'aimait pas AMD. Il se pourrait que le mot de passe soit le nom de son constructeur de cartes graphiques préféré. \nLes mots de passe sont parfois tout en majuscules avec les O majuscules qui peuvent être remplacés par des 0 et les i par des 1",
        password: "179c542119523a1d092290b605b5bb787c383d92ff3567b64d203b301e108e64", //Mot de passe en claire : NV1D1A
    },
    {
        enigme: "code",
        fullname: "Développement Informatique",
        user: "marjorie.flowmatic@greenternet.com",
        indication: "Marjorie a expliqué qu’elle aimait bien le créateur du langage de programmation c++ (Mr Stroustrup). \nIl se pourrait que le mot de passe soit le nom de famille de quelqu’un qu’elle aime bien.\nLes mots de passe sont parfois tout en majuscules avec les O majuscules qui peuvent être remplacés par des 0 et les i par des 1",
        password: "35864f725579f1de38a8c5d93e0e61343314d5c8163fcf86c5f6a409f947904b", //Mot de passe en claire : STR0USTRUP
    },
    {
        enigme: "admin",
        fullname: "Admin",
        user: "superadmin@greenternet.com",
        indication: "Bien tenté, mais tu n'hackeras pas ce mot de passe ! \n[Attention zone réservé au personnel autorisé, seul l'administrateur du jeu a le droit d'y aller]",
        password: "676adf1af71b7e9ea319d019db3caf07cdbf864310559311d0fd10ebe1ccb586" //Mot de passe en claire : ADM1N
    },
];