//Importations de bibliothèques
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, useMediaQuery } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';

import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import StepButton from '@mui/material/StepButton';

import Cookies from 'js-cookie';

import { expectedCode } from '../data/settingsdata';//Réponses attendues
import { useNavigate } from 'react-router-dom';

//Objet qui porte les couleurs cible de chaque énigme
let objColor = {
  blue: '#009ec7',//'Admin Réseau'
  orange: '#c75800',//'Comminication Digitale'
  purple: '#5b00cb',//'Base de données'
  pink: '#cc005d',//'Hardware'
  green: '#12ae65',//'Code et programmation'
}

//Création d'un thème de couleur pour la couleur de certains éléments sur la page
const theme = createTheme({
  palette: {
    blue: {
      main: objColor.blue,
    },
    orange: {
      main: objColor.orange,
    },
    purple: {
      main: objColor.purple,
    },
    pink: {
      main: objColor.pink,
    },
    green: {
      main: objColor.green,
    },
  },
});
//Fonction qui renvoi un couelur en fonction de l'indice, les index sont ceux du tableau 'steps'
const getColor = (index) => {
  switch (index) {
    case 0:
      return objColor.blue;
    case 1:
      return objColor.orange;
    case 2:
      return objColor.purple;
    case 3:
      return objColor.pink;
    case 4:
      return objColor.green;

    default:
      return 'black';
  }
}

//Objet/Tableau qui porte les informations de chaque énigme
const steps = [
  {
    label: 'Administration Réseau',
    description: `Nos allié.es nous aideront à diffuser l'anti-virus. Quelle est la clé de communication pour entrer en contact rapidement ?`,
    color: 'blue',
    labelTextField: 'Code de communication'
  },
  {
    label: 'Comminication Digitale',
    description: `Combien de personne, à minima, ont été informées qu'un virus avait infecté le Greenternet ?`,
    color: 'orange',
    labelTextField: 'Nombre de likes'
  },
  {
    label: 'Base de données et Data',
    description: `Sur quel créneau horaire l'anti-virus doit être envoyé sur le réseau ?`,
    color: 'purple',
    labelTextField: 'Horaire'
  },
  {
    label: 'Hardware',
    description:
      "Pour pouvoir étre diffusé sur le réseau, l'anti-virus doit être crypté. Pour cela, il a besoin du caractère d'encodage du Greenternet. Quel est ce caractère ?",
    color: 'pink',
    labelTextField: 'Caractére special'
  },
  {
    label: 'Code et Programmation',//Son titre à afficher
    description: "Dans quel langage doit etre codé l'anti-virus",//La description au-dessus de la saisie
    color: 'green',//la couelur dans 'theme'
    labelTextField: 'Langage utilisé'//Le placeholder dans la zone de saisie
  },

];

//Transition qui fais apparaitre la vidéo de fin
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//FOnction de rendu
const CodeInput = (props) => {
  //état des saisies
  const [code, setCode] = useState(['', '', '', '', '']);


  //actualisation des états
  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
  };

  //Fonction qui vérifie si les réponses sont bonnes
  const handleValidation = () => {
    const isCodeValid = code.every((c, i) => c.toUpperCase() === expectedCode[i]);

    if (isCodeValid) {//si le code est valide
      handleClickOpenS();//ouverture de la vidéo de fin
      //set cookie fin du jeu
      Cookies.set('EndGame', 1, { path: '/' });
    } else {//sinon afficher "Code invalid"
      handleClickError();
    }
  };

  ////////Partie Alerts
  const [open, setOpen] = React.useState(false);
  //Ouverture de l'alerte
  const handleClickError = () => {
    setOpen(true);
  };

  //Fermeture de l'alerte
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  ////////Partie Stepeur (gestion des étapes de saisies)
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  //Détection de l'utilisation de la touche ENTER dans une saisie pour passer à la saisie suivante
  const handleEnterKey = (event) => {
    if (event.code === "Enter") {
      handleComplete();
    }
  }

  //Remmetre à zero les étapes
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  ////////Partie screen de fin
  const [openS, setOpenS] = React.useState(false);

  const handleClickOpenS = () => {
    setOpenS(true);
  };


  const [isVideoEnded, setIsVideoEnded] = React.useState(false);

  const handleVideoEnded = () => {
    setIsVideoEnded(true);
  };

  const navigate = useNavigate();

  const reset = () => {
    clearInterval(props.intervalID);
    Object.keys(Cookies.get()).forEach(function (cookieName) {
        var neededAttributes = {
            path: '/',
        };
        Cookies.remove(cookieName, neededAttributes);
    });
    navigate('/');
    Cookies.set('EndGame', 0, { path: '/' });
    localStorage.clear();

    window.location.reload();
}

const videoRef = React.useRef(null);

useEffect(() => {
  if (videoRef.current) {
    // Ajustez cette valeur pour contrôler la vitesse de la vidéo
    // 1.0 est la vitesse normale, 2.0 est deux fois plus rapide, etc.
    videoRef.current.playbackRate = 2.5;
  }
}, []);

  const isXsBreakPoint = useMediaQuery('(max-width: 420px)');
  const isTabletBreakpoint = useMediaQuery('(max-width: 1024px)');

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh', justifyContent: 'center' }}>
        <Box sx={{ alignItems: 'center' }} m={5}>
          <Typography variant='h5' align='center' fontSize={isXsBreakPoint ? '16px' : '24px'} paddingTop={'0px'} sx={{ mb: 2 }}>Remplissez les champs avec les informations que vous avez obtenu, ça nous permettra de désamorcer le virus plus tard.</Typography>
        </Box>
        <Box sx={{ width: '90%', marginTop: '-80px'}}>
          <Stepper className='hideScroll' sx={{maxWidth: '1349px' ,overflowX: 'scroll', height: '150px'}} nonLinear activeStep={activeStep}>
            {steps.map((step, index) => (//on boucle pour afficher toutes les étapes
              <Step key={step.label} completed={completed[index]}>
                <ThemeProvider theme={theme}>
                  <StepButton sx={{minWidth: isTabletBreakpoint ? '250px' : ''}} onClick={handleStep(index)}>
                    <Typography color={getColor(index)}>{step.label}</Typography>
                  </StepButton>
                </ThemeProvider>
              </Step>
            ))}
          </Stepper>
          <div>
            {allStepsCompleted() ? (//Afficher la validation de saisie seulement si toutes les étapes ont été saisie
              <React.Fragment>
                <Paper square elevation={3} sx={{ p: 3, m: 3 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography sx={{textAlign: isXsBreakPoint ? 'center' : 'left'}}>Vous avez saisi toutes les informations ! Vous pouvez valider les informations pour désamorcer le virus.</Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
                      <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} variant="contained">
                        Remise à zéro
                      </Button>
                      <Button onClick={handleValidation} sx={{ mt: 1, mr: 1 }} variant="contained" >
                        Valider
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </React.Fragment>
            ) : (//Sinon afficher les zones de saisie
              <React.Fragment>
                <Paper square elevation={3} sx={{ p: 2, m: 3, marginTop: '-30px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography color={getColor(activeStep)}>{steps[activeStep].description}</Typography>
                    <ThemeProvider theme={theme}>
                      <TextField
                        key={activeStep}
                        value={code[activeStep]}
                        color={steps[activeStep].color}
                        label={steps[activeStep].labelTextField}
                        onChange={(e) => handleChange(activeStep, e.target.value)}
                        onKeyDown={handleEnterKey}
                        inputProps={{ maxLength: 6, style: { textAlign: 'center', fontSize: '2rem', height: '3rem' } }}
                        sx={{ mt: 1 }}
                      />
                    </ThemeProvider>
                  </Box>
                </Paper>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Précédent
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== steps.length && //Afficher le bouton fin ou suivant en fonction de l'étape de saisie
                    <Button onClick={handleComplete}>
                      {completedSteps() === totalSteps() - 1
                        ? 'Fin'
                        : 'Suivant'}
                    </Button>
                  }
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Box>

      <Snackbar open={open} data-testid="alert-wrong-answer" autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Les réponses saisies sont invalides.
        </Alert>
      </Snackbar>

      {isVideoEnded && <Snackbar open={true} data-testid="alert-end-game" autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }}>
          Vous avez désamorcé le virus avec succès ! Cliquer sur rejouer pour recommencer !
          <Button
                    sx={{
                        display: 'flex',
                        marginTop: '10px',
                        background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                    }}
                    data-testid="reset-button"
                    variant="contained"
                    size="small"
                    onClick={reset}
                >
                    Rejouer
                </Button>
        </Alert>
      </Snackbar>}

      <div className='videoBox'>
        <Dialog
          fullScreen
          open={openS}
          TransitionComponent={Transition}
          sx={{ zIndex: 100}}
          data-testid="video-fin"
          
        >
          <video ref={videoRef} className='finalVideo' onEnded={handleVideoEnded} muted width="100%" height="100%" playsInline autoPlay style={{ backgroundColor: 'black', overflow: 'hidden'}}>
            <source src="videos/Création_de_lanti-virus_done.webm" type="video/webm"></source>
            <source src="videos/Création_de_lanti-virus_done.mp4" type="video/mp4"></source>
            Votre navigateur ne supporte pas les vidéos HTML5.
          </video>
        </Dialog>
      </div>
    </>
  );
};

export default CodeInput;