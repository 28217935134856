import { Box, useMediaQuery } from '@mui/material';
import * as React from 'react';
import Commande from './Commande';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TerminalFinal from './TerminalFinal';
import HelpIcon from '@mui/icons-material/Help';

import EnigmeContext from '../EContext';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    textAlign: 'center',
}));

const ItemT = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
}));

//tableau des couleurs des liens, couleur homogène par défaut
let colorL = {
    "NOUS-S1": '#B0FCFF',
    "NOUS-S9": '#B0FCFF',
    "NOUS-S3": '#B0FCFF',
    "S9-S3": '#B0FCFF',
    "S9-S7": '#B0FCFF',
    "S5-S1": '#B0FCFF',
    "S5-S7": '#B0FCFF',
    "S5-S10": '#B0FCFF',
    "S10-ALL-2": '#B0FCFF',
    "S2-ALL-2": '#B0FCFF',
    "S8-ALL-2": '#B0FCFF',
    "S2-S7": '#B0FCFF',
    "S8-S7": '#B0FCFF',
    "S10-S7": '#B0FCFF',
    "S3-S4": '#B0FCFF',
    "S4-ALL-1": '#B0FCFF',
    "S2-ALL-1": '#B0FCFF',
    "S6-ALL-1": '#B0FCFF',
    "S4-S6": '#B0FCFF',
    "S6-S8": '#B0FCFF',
    "S6-S1": '#B0FCFF',
}


export default function EnigmeNetwork3() {

    const [open, setOpen] = React.useState(false);

    const { setDisableAll } = React.useContext(EnigmeContext);
    React.useEffect(() => {
        setDisableAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Ouvre le modal "commande"
    const handleClickOpen = () => {
        setOpen(true);
    };

    //trace le schéma du réseau selon les couleurs des liens dans colorL
    const drawPath = () => {
        let context, canvaso;
        canvaso = document.querySelector('canvas');
        context = canvaso.getContext('2d');
        context.lineWidth = 5;
        //lignes
        //NOUS - S1
        context.strokeStyle = colorL["NOUS-S1"];
        context.beginPath();
        context.moveTo(238, 76);
        context.lineTo(249, 133);
        context.stroke();
        context.closePath();

        //NOUS - S9
        context.strokeStyle = colorL["NOUS-S9"];
        context.beginPath();
        context.moveTo(259, 51);
        context.lineTo(434, 66);
        context.stroke();
        context.closePath();

        //NOUS - S3
        context.strokeStyle = colorL["NOUS-S3"];
        context.beginPath();
        context.moveTo(210, 56);
        context.lineTo(51, 122);
        context.stroke();
        context.closePath();

        //S9 - S1
        context.strokeStyle = colorL["S9-S1"];
        context.beginPath();
        context.moveTo(266, 148);
        context.lineTo(435, 68);
        context.stroke();
        context.closePath();

        //S9 - S7
        context.strokeStyle = colorL["S9-S7"];
        context.beginPath();
        context.moveTo(451, 84);
        context.lineTo(405, 157);
        context.stroke();
        context.closePath();

        //S5 - S1
        context.strokeStyle = colorL["S5-S1"];
        context.beginPath();
        context.moveTo(266, 148);
        context.lineTo(533, 150);
        context.stroke();
        context.closePath();

        //S5 - S7
        context.strokeStyle = colorL["S5-S7"];
        context.beginPath();
        context.moveTo(533, 150);
        context.lineTo(421, 174);
        context.stroke();
        context.closePath();

        //S5 - S10
        context.strokeStyle = colorL["S5-S10"];
        context.beginPath();
        context.moveTo(553, 166);
        context.lineTo(580, 246);
        context.stroke();
        context.closePath();

        ////S10 - ALLIÃ©.ES 2
        context.strokeStyle = colorL["S10-ALL-2"];
        context.beginPath();
        context.moveTo(561, 261);
        context.lineTo(454, 323);
        context.stroke();
        context.closePath();

        //ALLIÃ©.ES 2 - S2
        context.strokeStyle = colorL["S2-ALL-2"];
        context.beginPath();
        context.moveTo(404, 347);
        context.lineTo(292, 409);
        context.stroke();
        context.closePath();

        //ALLIÃ©.ES 2 - S8
        context.strokeStyle = colorL["S8-ALL-2"];
        context.beginPath();
        context.moveTo(407, 305);
        context.lineTo(333, 262);
        context.stroke();
        context.closePath();

        //S2 - S7
        context.strokeStyle = colorL["S2-S7"];
        context.beginPath();
        context.moveTo(403, 188);
        context.lineTo(282, 386);
        context.stroke();
        context.closePath();

        //S7 - S8
        context.strokeStyle = colorL["S8-S7"];
        context.beginPath();
        context.moveTo(389, 172);
        context.lineTo(315, 247);
        context.stroke();
        context.closePath();

        //S7 - S10
        context.strokeStyle = colorL["S10-S7"];
        context.beginPath();
        context.moveTo(404, 188);
        context.lineTo(562, 262);
        context.stroke();
        context.closePath();

        //S3 - S4
        context.strokeStyle = colorL["S3-S4"];
        context.beginPath();
        context.moveTo(28, 151);
        context.lineTo(25, 273);
        context.stroke();
        context.closePath();

        //S4 - ALLIÃ©.ES 1
        context.strokeStyle = colorL["S4-ALL-1"];
        context.beginPath();
        context.moveTo(25, 305);
        context.lineTo(90, 378);
        context.stroke();
        context.closePath();

        //ALLIÃ©.ES 1 - S2
        context.strokeStyle = colorL["S2-ALL-1"];
        context.beginPath();
        context.moveTo(127, 405);
        context.lineTo(259, 412);
        context.stroke();
        context.closePath();

        //S6 - ALLIÃ©.ES 1
        context.strokeStyle = colorL["S6-ALL-1"];
        context.beginPath();
        context.moveTo(113, 377);
        context.lineTo(146, 270);
        context.stroke();
        context.closePath();

        //S4 - S6
        context.strokeStyle = colorL["S4-S6"];;
        context.beginPath();
        context.moveTo(40, 284);
        context.lineTo(129, 248);
        context.stroke();
        context.closePath();

        //S6 - S8
        context.strokeStyle = colorL["S6-S8"];;
        context.beginPath();
        context.moveTo(160, 245);
        context.lineTo(298, 262);
        context.stroke();
        context.closePath();

        //S6 - S1
        context.strokeStyle = colorL["S6-S1"];;
        context.beginPath();
        context.moveTo(246, 163);
        context.lineTo(147, 235);
        context.stroke();
        context.closePath();


        //ronds
        //NOUS
        context.strokeStyle = '#1DDE10';
        context.save();
        context.translate(235.5, 52);
        context.scale(1, 1);
        context.beginPath();
        context.arc(0, 0, 25, 0, 6.283185307179586, false);
        context.fillStyle = '#1DDE10';
        context.fill();
        context.stroke();
        context.closePath();
        context.restore();
        context.fillStyle = "black";
        context.font = "15px Arial";
        context.fillText("NOUS", 215, 58);
        context.font = "15px Arial";
        context.fillText("52.59.222.97", 215, 92);

        //ALLIÃ©.ES 2
        context.strokeStyle = '#1DDE10';
        context.save();
        context.translate(426, 327);
        context.scale(1, 1);
        context.beginPath();
        context.arc(0, 0, 28, 0, 6.283185307179586, false);
        context.fillStyle = '#1DDE10';
        context.fill();
        context.stroke();
        context.closePath();
        context.restore();
        context.fillStyle = "black";
        context.font = "13px Arial";
        context.fillText("Allie.es 2", 400, 332);
        context.font = "15px Arial";
        context.fillText("111.206.198.31", 400, 370);

        //ALLIÃ©.ES 1
        context.strokeStyle = '#1DDE10';
        context.save();
        context.translate(104.5, 396);
        context.scale(1, 1);
        context.beginPath();
        context.arc(0, 0, 25, 0, 6.283185307179586, false);
        context.fillStyle = '#1DDE10';
        context.fill();
        context.stroke();
        context.closePath();
        context.restore();
        context.fillStyle = "black";
        context.font = "12px Arial";
        context.fillText("Allie.es 1", 80, 401);
        context.font = "15px Arial";
        context.fillText("34.76.60.69", 80, 436);

        //rectangles
        //S9
        context.strokeStyle = '#00D7DE';
        context.strokeRect(435, 50, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(435, 50, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S9", 435, 84);
        context.font = "15px Arial";
        context.fillText("167.88.60.250", 435, 100);

        //S1
        context.strokeStyle = '#00D7DE';
        context.strokeRect(232, 132, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(232, 132, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S1", 232, 166);
        context.font = "15px Arial";
        context.fillText("46.229.161.131", 232, 186);

        //S7
        context.strokeStyle = '#00D7DE';
        context.strokeRect(387, 155, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(387, 155, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S7", 387, 189);
        context.font = "15px Arial";
        context.fillText("5.182.210.16", 387, 209);

        //S5
        context.strokeStyle = '#00D7DE';
        context.strokeRect(535, 134, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(535, 134, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S5", 535, 168);
        context.font = "15px Arial";
        context.fillText("91.121.180.203", 495, 188);

        //S10
        context.strokeStyle = '#00D7DE';
        context.strokeRect(563, 248, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(563, 248, 34, 34);
        context.fillStyle = "black";
        context.font = "19px Arial";
        context.fillText("S10", 563, 282);
        context.font = "15px Arial";
        context.fillText("31.13.127.11", 513, 302);

        //S2
        context.strokeStyle = '#00D7DE';
        context.strokeRect(260, 389, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(260, 389, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S2", 260, 423);
        context.font = "15px Arial";
        context.fillText("194.71.211.50", 260, 443);

        //S8
        context.strokeStyle = '#00D7DE';
        context.strokeRect(299, 248, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(299, 248, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S8", 299, 282);
        context.font = "15px Arial";
        context.fillText("79.152.164.28", 299, 302);

        //S6
        context.strokeStyle = '#00D7DE';
        context.strokeRect(129, 235, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(129, 235, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S6", 129, 269);
        context.font = "15px Arial";
        context.fillText("105.112.100.226", 129, 289);

        //S4
        context.strokeStyle = '#00D7DE';
        context.strokeRect(8, 272, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(8, 272, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S4", 8, 306);
        context.font = "15px Arial";
        context.fillText("192.71.18.47", 8, 326);

        //S3
        context.strokeStyle = '#00D7DE';
        context.strokeRect(15, 115, 34, 34);
        context.fillStyle = '#00D7DE';
        context.fillRect(15, 115, 34, 34);
        context.fillStyle = "black";
        context.font = "25px Arial";
        context.fillText("S3", 15, 149);
        context.font = "15px Arial";
        context.fillText("111.206.198.123", 15, 169);
    }
    React.useEffect(() => {
        drawPath();
    }, []);


    const isMdBreakpoints = useMediaQuery('(max-width:1202px)');

    return (
        <>
            <Box>
                <Grid container spacing={0.5}>
                    <Grid  xs={12} md={12} lg={6}>
                        <Item sx={{paddingBottom: '10px', overflowY: 'auto', height: 'fit-content', paddingRight:"5px"}}>
                            <canvas className='networkCanva' width='600vh' height='450vh'>
                            </canvas>
                        </Item>
                    </Grid>
                    {!isMdBreakpoints && (
                        <Grid xs={6}>
                            <Item><img style={{cursor: "pointer", objectFit: "contain"}} alt='commande' src="/img/network_img/commandeDeBase.png" width='500px' height='460px'  onClick={handleClickOpen}></img></Item>
                        </Grid>
                    )}
                    {isMdBreakpoints && ( 
                        <div className="helpIcon" onClick={handleClickOpen}>
                            <HelpIcon sx={{ fontSize: 35, position: 'absolute', top:'90px', left: '20px', cursor: 'pointer'}} />
                        </div>
                    )}
                    <Grid xs={12}>
                        <ItemT><TerminalFinal /></ItemT>
                    </Grid>
                    <Grid xs={6}>
                        <Item>
                        </Item>
                    </Grid>
                </Grid>
                <Commande open={open} setOpen={setOpen}></Commande>
            </Box>
        </>
    )
}