//Importation des bibliotheques
import Video from "../components/Video";
import EnigmeCodeProgra1 from "../components/code/EnigmeCodeProgra1";
import EnigmeCodeProgra2 from "../components/code/EnigmeCodeProgra2";
import EnigmeCodeProgra3 from "../components/code/EnigmeCodeProgra3";
import EnigmeSocial1 from "../components/social/EnigmeSocial1";
import EnigmeHardware1 from "../components/hardware/EnigmeHardware1";
import Robot from "../components/hardware/Robot";
import EnigmeNetwork1 from "../components/network/EnigmeNetwork1";
import EnigmeNetwork2 from "../components/network/EnigmeNetwork2";
import EnigmeBDD1 from "../components/bdd/EnigmeBDD1";
import EnigmeNetwork3 from "../components/network/EnigmeNetwork3";

//Object des structure de chaque énigme
export const challenges = [
    {
        enigme: "network",//Nom clé de l'énigme, ce nom, on le retrouve dans plusieurs js data, il est important, il sert à faire le lien
        steps : [//Étapes de chaque énigme, enchainement des d'afférentes pages
            <EnigmeNetwork1/>,
            <EnigmeNetwork2/>,
            <EnigmeNetwork3/>
        ]
    },
    {
        enigme: "social",
        steps : [
            <EnigmeSocial1/>,
            <EnigmeSocial1/>,
            <EnigmeSocial1/>,
            <EnigmeSocial1/>,
            <EnigmeSocial1/>,
            <EnigmeSocial1/>
        ]
    },
    {
        enigme: "bdd",
        steps : [
            //Vidéo (url du fichier), (transcription sous la vidéoen question), next : (afficher le seulement le bouton Suivant)
            <Video url="/videos/bdd/marie-intro.mp4" transcription = "Le code pour anéantir le virus doit être lancé quand tous les serveurs infectés sont tous allumés en même temps. Sinon, il risque de se propager encore. Analysez les graphiques afin de trouver l'heure à laquelle les serveurs sont tous allumés." next/>,
            <EnigmeBDD1/>,
            <Video url="/videos/bdd/marie-outro.mp4" transcription="Bien joué ! Notez bien l'heure à laquelle le code devra être lancé."/>,
        ]
    },
    {
        enigme: "hardware",
        steps : [
            //Vidéo (url du fichier), (transcription sous la vidéoen question), next : (afficher le seulement le bouton Suivant)
            <Video url="/videos/hardware/laetitia-intro.mp4" transcription="Trouvez le caractère d'encodage. Pour cela, recablez le matériel relié au robot. Une fois que vous l'avez trouvé, retenez bien le caractère d'encodage." next/>,
            <EnigmeHardware1/>,
            <Robot/>,
            //Vidéo (url du fichier), (transcription sous la vidéoen question), prev : (afficher le seulement le bouton Précédent)
            <Video url="/videos/hardware/laetitia-outro.mp4" transcription="Bravo, vous avez réussi à retrouver le caractère d'encodage !" prev/>,
        ]
    },
    {
        enigme: "code",
        steps : [
            //Vidéo (url du fichier), (transcription sous la vidéoen question), next : (afficher le seulement le bouton Suivant)
            <Video url="/videos/code-et-prog/marjorie-intro.mp4"
                title=""
                transcription="Identifiez les objets remplis de codes (car le virus les a tous infectés)."
                next
            />,
            <EnigmeCodeProgra1/>,
            //Vidéo (url du fichier), (transcription sous la vidéoen question), next : (afficher le seulement le bouton Suivant)
            <Video url="/videos/code-et-prog/marjorie-2.mp4"
            transcription="Trouvez dans quel langage le virus a été codé ! Pour cela, Marjorie vous transmet 8 caractères qui correspondent à 8 langages..."
            next
            />,
            <EnigmeCodeProgra2/>,
            //Vidéo (url du fichier), (transcription sous la vidéoen question), next : (afficher le seulement le bouton Suivant)
            <Video url="/videos/code-et-prog/marjorie-outro.mp4"
            transcription="Retenez bien le langage dans lequel le Virus a été fait, car c'est essentiel pour que l'on puisse le désactiver."
            next
            />,
            <EnigmeCodeProgra3/>,
        ]
    },
];