//Importation des bibliotheques
import React from 'react';
import { Grid, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { colors, colors2 } from '../../data/bdddata';//importation des couleurs pour les colonnes et les lignes

//Style des boutons du launchpad
const LaunchpadButton = styled(Button)(({ columnindex, isbuttonpressed }) => ({
    background: isbuttonpressed ? `radial-gradient(circle, white, ${colors[columnindex]})` : `radial-gradient(circle, white 30%, grey)`,
    minWidth: '48px',
    minHeight: '48px',
    borderRadius: '5%',
    boxShadow: isbuttonpressed ? `0 0 2px ${colors[columnindex]}, 0 0 4px ${colors[columnindex]}, 0 0 8px ${colors[columnindex]}` : "",
    '&:hover': {
        boxShadow: isbuttonpressed ? `0 0 2px ${colors[columnindex]}, 0 0 4px ${colors[columnindex]}, 0 0 8px ${colors[columnindex]}` : "",
    },
}));

//Fonction de rendu de la page
const Launchpad = (props) => {

    //Fonction spécifique à exécuter lorsqu'un bouton est cliqué
    const handleButtonClick = (row, column) => {

        if (column !== 8) {// Empêche de faire une action sur la dernière colonne
            // Vérifie si le bouton est déjà appuyé
            const isButtonPressed = props.pressedButtons.find((button) => button.row === row && button.column === column);

            // Si le bouton est déjà appuyé, le retire de la liste
            if (isButtonPressed) {
                props.setPressedButtons((prevButtons) =>
                    prevButtons.filter((button) => !(button.row === row && button.column === column))
                );
            } else {
                // Si le bouton n'est pas appuyé, l'ajoute à la liste
                props.setPressedButtons((prevButtons) => [...prevButtons, { row, column }]);
            }
        }
    };

    return (
        <Box sx={{ backgroundColor: 'black', padding: '16px', margin: "auto" }} width={'531px'} height={'531px'}>
            <Grid container spacing={1}>
                <Grid container item spacing={1}>
                    {colors.map((_, columnIndex) => (//Premiére ligne les (1,2,3,4,5,6,7,8) de couleurs
                        <Grid item key={columnIndex}>
                            <Box sx={{
                                background: colors[columnIndex],
                                minWidth: '48px',
                                minHeight: '48px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: `0 0 5px ${colors[columnIndex]}, 0 0 10px ${colors[columnIndex]}, 0 0 15px ${colors[columnIndex]}`,
                            }}>
                                <Box
                                    sx={{
                                        background: 'black',
                                        minWidth: '38px',
                                        minHeight: '38px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography sx={{ color: 'white' }}>
                                        {columnIndex + 1}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {colors.map((_, rowIndex) => (//Chaque ligne
                    <Grid container item key={rowIndex} spacing={1}>
                        {colors.map((_, columnIndex) => {//Chaque colonne
                            const isButtonPressed = props.pressedButtons.find((button) => button.row === rowIndex + 1 && button.column === columnIndex);//récupére pour un bouton donné si il est déja appuyé
                            return (
                                <Grid item key={columnIndex}>
                                    <LaunchpadButton
                                        variant="contained" //Bouton clickable par défaut gris/blanc au centre du launchpad
                                        columnindex={columnIndex}
                                        isbuttonpressed={isButtonPressed}
                                        onClick={() => handleButtonClick(rowIndex + 1, columnIndex)}//au clique d'un bouton, executer handleButtonClick()
                                    />
                                </Grid>
                            );
                        })}
                        <Grid item key={rowIndex} >
                            <Box
                                sx={{
                                    background: colors2[rowIndex],//derniére colonne les H (H1,H2,H3,H4,H5,H6,H7,H8) de couleur et non clickable
                                    minWidth: '48px',
                                    minHeight: '48px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxShadow: `0 0 5px ${colors2[rowIndex]}, 0 0 10px ${colors2[rowIndex]}, 0 0 15px ${colors2[rowIndex]}}`
                                }}
                            >
                                <Typography sx={{ color: 'white' }}>
                                    {"H" + (rowIndex + 1)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Launchpad;
