import * as React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import RequiredMentions from "./RequiredMentions";

export default function Intro(props) {
  const navigate = useNavigate();

  return (
    <div className="introWrapper">
      <Card
        sx={{ maxWidth: { xs: "90%", sm: "90%", md: "50%" }, margin: '0 auto' }}
      >
        <CardMedia component="video" role="video" controls image={props.url} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.transcription}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ margin: "auto", justifyContent: "center", marginTop: "15px" }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/hub")}
              size="large"
              sx={{
                background: "linear-gradient(45deg, #6B22E4, #AB9230)",
              }}
            >
              HUB
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <RequiredMentions />
    </div>
  );
}
