import * as React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function NoticeBDD(props) {

    const handleClose = () => {
        props.setOpen(false);
    };


    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Notice BDD
            </DialogTitle>
            <DialogContent>
                <img alt="notice" src={props.src} width={"100%"}/>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};