const CodeComponent = () => {
  return (
    <div style={styles.container}>
      <pre>
        <span style={styles.keyword}>Si</span>(<span style={styles.code}>monCaractèreATester</span> == <span style={styles.code}>leCaractèreDuBonLangage</span>) {'{'}
        <br />
        &nbsp;&nbsp;Affiche <span style={styles.string}>“Succès ! Vous avez utilisé le bon caractère.
        <br />
        &nbsp;&nbsp;Trouvez maintenant le langage associé à ce caractère.”</span>;
        <br />
        {'}'}
        <span style={styles.keyword}>Sinon</span>{'{'}
        <br />
        &nbsp;&nbsp;Affiche <span style={styles.string}>“Échec ! Essayez un autre caractère.”</span>;
        <br />
        {'}'}
      </pre>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#1e1e1e',
    padding: '20px 50px',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
   overflow: 'scroll',
    maxWidth: 'fit-content',
    margin: 'auto',
    marginTop: '50px',
    color: '#FFFFFF',
    fontSize: '1.5rem',
    fontFamily: 'monospace',
    marginBottom: '20px'
    
  },
  code: {
    color: '#00aaff',
  },
  string: {
    color: '#ffa500',
  },
  keyword: {
    color: '#ff00ff',
  }
};

export default CodeComponent;
