import { Box } from "@mui/material";
import * as React from "react";
import { Typography } from "@mui/material";

import { Link } from "react-router-dom";

export default function TerminalFinal() {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "black",
          width: "100%",
          height: "200px",
          overflowY: "auto",
        }}
      >
        <Typography paddingLeft="15px" color="white">
          Terminal - Connecté en tant que @Greenternet-console
        </Typography>
        <Box id="terminal">
          <Box margin="10px">
            <Typography id="reponseDora" color="white">
              {"> "}
              {
                "Bien joué ! J'ai reçu à l'instant un message du serveur que vous avez atteint : Connexion établie - Code de communication :  4c  - Temps de réponse : 0.02s. Retenez bien le code de communication. Et merci d'avoir établi la connexion avec ces allié·es !"
              }
            </Typography>
          </Box>
          <Link to="/hub" style={{ textDecoration: "none" }}>
            <Box margin="10px">
              <Typography
                color="white"
                sx={{
                  cursor: "pointer",
                  transition: "color 0.3s ease",
                  "&:hover": {
                    color: "#6b22e4",
                  },
                }}
              >
                {"######"}
                {" Retourner au hub "}
                {"######"}
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
    </>
  );
}
