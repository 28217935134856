export const socialData = [
    { pseudo: "croujatique", content: "J’ai une #question ! L’eau de #pluie, on peut la boire ou c’est #toxique ?", likes: "5277", date: "Il y a 3h" },
    { pseudo: "boow", content: "Une fois de plus, #Greenternet fait ses preuves. Hier j’ai lu que #Facevache fait passer 10 fois plus d’informations douteuses !", likes: "2277", date: "Il y a 4h" },
    { pseudo: "Dylan_du_13", pp: "Dylan.png", content: "Putain, marre de ma conne de collègue qui sait rien faire. En vrai, les filles #restezchezvous. Pas besoin de vous au #travail. #coupdegueule", likes: "1117", date: "Il y a 3h" },
    { pseudo: "Mr.Night34", pp: "", content: "Dernier #album de @CrashCourse DE LA BOMBE ! #Crashcourse #musique #rap", likes: "210", date: "Il y a 1h" },
    { pseudo: "BoyPosé", content: "On continue dans les #suggestions #musicales. Aujourd’hui : Stairway to Heaven de Led Zeppelin, 1971", likes: "8253", date: "Il y a 1h" },
    { pseudo: "cruterme", content: "- Sophie !<br />- Oui ?<br />- Oui ?<br />- Oui ?<br />- Oui ?<br />- Oui ?<br />- Oui ?", image: "sophie.jpg", likes: "5775", date: "Il y a 1h" },
    { pseudo: "bestfanPSG", pp: "PSG.png", content: "L’#arbitre a clairement favorisé l’#ASSE. D’où vient-il ? mmmh après une petite recherche : de SAINT ETIENNE !! #favoritisme #allezPSG", likes: "8047", date: "Il y a une semaine" },
    { pseudo: "pépé_mayo", content: "#restezchezvous #restezchezvous #restezchezvous", likes: "4520", date: "Il y a 10 minutes" },
    { pseudo: "Tom924019488", content: "Dites @BaBei_electronics votre #8G la, à part #espionner tous les habitants de la #planète et leur balancer un #virus, elle va servir à quoi concrètement ? J’enjoigne tous mes #followers à #restezchezvous pour ne pas vous faire voler vos #données ! 8GPandémie", image: "8GVirusAaaah.jpg", likes: "8219", date: "Il y a 1h" },
    { pseudo: "CanardFrench", content: "Enfin une bonne #initiative de @AlpesEco concernant la #protection des #loups dans nos territoires !  #protectiondesanimaux #AlpesEco", likes: "8836", date: "Il y a 5 jours" },
    { pseudo: "tormâta", pp: "img2.jpg", content: "Là, je me dois de pousser un #coupdegueule : la #circulation encore #bloquée à cause de ces débiles de #manifestants pour des causes stupides telles que le #fémnisime et l’#écologie. Franchement, #restezchezvous ! #onenamarre", image: "IMG20501004-9249203.jpg", likes: "2266", date: "Il y a 5h" },
    { pseudo: "tormâra", pp: "img2.jpg", content: "Mon alter-ego ! #forEver", image: "image.jpg", likes: "1607", date: "Il y a 3 jours" },
    { pseudo: "Ecologie_en_force", pp: "herisson.jpg", content: "Rejoignez-nous place de l’Hôtel de Ville à Paris pour une #manifestation #pacifique. Faisons entendre nos voix pour l’#écologie. Une #vague de #pollution annoncée à Paris. Ce n’est plus possible et tolérable !", likes: "401", date: "Il y a 3h" },
    { pseudo: "pépé_mayo", content: "#restezchezvous #restezchezvous #restezchezvous", likes: "118", date: "Il y a 10 minutes" },
    { pseudo: "h€laig_", pp: "chatte.jpg", content: "Le télétravail avec elle à côté de moi : impossible ! #télétravail #chat #chatte #mignonne", image: "Ma Loulou damour.jpg", likes: "3863", date: "Il y a 1h" },
    { pseudo: "ponm@dour", content: "L’équipe de #handball arrive en demi-finale de la coupe du monde après une #victoire écrasante face au Pérou ! Ces meufs sont trop fortes ! #demifinale #coupedumondehandball #allezlesbleues #ilovegirls", likes: "4039", date: "Il y a 9h" },
    { pseudo: "Lola2039", pp: "1000x563_néon.jpg", content: "L’actrice @MillieBBrown est vraiment incroyable dans #StrangerThings vieille #série des années 80. Je vous conseille la série à toutes !!", image: "miliebbrown.jpg", likes: "9589", date: "Il y a 2h" },
    { pseudo: "MasterVirus", pp: "Greenternet et virus.png", content: "Les #débats à l’#Assemblée nationale encore #bloqués à cause de ces tarés de #feministes qui empêche nos #élus de parler des vrais sujets. #restezchezvous #onenamarre", likes: "721", date: "Il y a 4h" },
    { pseudo: "GREENenergy", pp: "jura.jpg", content: "Malgré les efforts considérables de tou·tes les citoyen·nes français·es, nous n’avons pu éviter la #vague de #pollution qui s’abat aujourd’hui sur #Grenoble. #restezchezvous", likes: "8738", date: "Il y a 7h" },
    { pseudo: "StreetLover", content: "Bon, les filles qui savent pas accepter les compliments, #restezchezvous", likes: "7980", date: "Il y a 7h" },
    { pseudo: "tormâra", pp: "img2.jpg", content: "Dans ma knoot précédente, je jokais. Cette personne n’existe pas, c’est une #AI qui a créé ce visage !", likes: "1202", date: "Il y a 9h" },
    { pseudo: "pépé_mayo", content: "#restezchezvous #restezchezvous #restezchezvous", likes: "218", date: "Il y a 10 minutes" },
    { pseudo: "doomerFR", pp: "doomer.jpeg", content: " - You look lonely, I can fix that. - Photo d'un agent de police dans le froid ! #agentK #real", image: "real.jpg", likes: "2049", date: "9 Septembre 2049" },
    { pseudo: "premier_degré", content: "Le saviez-vous ? Le mot « #thermoplastique » a la particularité de pouvoir être lu dans les 2 sens, dont un qui ne veut rien dire. #lesaviezvous #découverte", likes: "752", date: "Il y a 4 jours" },
    { pseudo: "JOOff", pp: "JO.jpeg", content: "Visionnez sur notre site les #meilleurs moments des #jeuxolympiques d’hiver 2050 : les chutes, les victoires, des larmes, du bonheur et 18 #médailles pour la #France !", likes: "2748", date: "Il y a un jour" },
    { pseudo: "TheDylan", content: "Non mais je rêve, le discours de @AlpesEco c’est vraiment de la merde. Comme si la #protectiondesanimaux nous intéressaient vraiment.", likes: "873", date: "Il y a 4h" },
    { pseudo: "PropaGrandeur", content: "Encore des conneries inutiles qui volent l’attention et les débats : le #greenternet. On vous ment. #restezchezvous", likes: "8578", date: "Il y a une semaine" },
    { pseudo: "santépubliquefrance", pp: "logo_ministere_sante.png", content: "L’épidémie de #grippe annuelle commence en #France #restezchezvous", likes: "8474", date: "Il y a 3h" },
    { pseudo: "p@py_mayo", content: "#protectiondesanimaux #protectiondesanimaux #protectiondesanimaux #protectiondesanimaux #protectiondesanimaux #protectiondesanimaux #protectiondesanimaux", likes: "7624", date: "Il y a 15 minutes" },
    { pseudo: "lindependant_journalOFF", pp: "jlogo.png", content: "EXCLUSIVITÉ : Retrouvez sur notre site internet une #interview #exclusive le·a directeur·trice général de @GREENenergy. Avec iels, nous abordons la question des #énergies #renouvelables, comment réparer le mal fait à la #planète, et beaucoup d’autres sujets.", likes: "4964", date: "Il y a 2 jours" },
    { pseudo: "StreetLover", content: "Encore une #femme #présidente des Etats-Unis… on aura vraiment tout vu. #MGTOW #Trump me manque ! #restezchezvous", likes: "8273", date: "Il y a 6h" },
    { pseudo: "pépé_mayo", content: "#restezchezvous #restezchezvous #restezchezvous", likes: "6275", date: "Il y a 10 minutes" },
    { pseudo: "dank_memeFR", pp: "pepe.jpg", content: "Tu t'es fait RickRoll, bien joué l'ami. #rickroll #meme", image: "rick.jpeg", likes: "1987", date: "Il y a 3 jours" }
]

export const target = {
    ban: ["#restezchezvous","pépé_mayo"],
    upload: ["thumbsupcat.png"],
}

export const steps = [
    {
        label: 'ban',
        title: 'Bannir le(s) bot(s) et le(s) hashtag(s) compromettant',
        goal: target.ban.length,
    },
    {
        label: 'upload',
        title: 'Uploader un Knoot',
        goal: target.upload.length,
    },
];

export const messageMarguerite = [
    {
        message:  "Le virus est une vraie pourriture. Il a créé un bot qui inonde Knooter de fausses informations. Il faut le stopper le plus vite possible et masquer tout ce qu’il publie. Il suffit d’aller sur le réseau social, de le repérer et de masquer ses knoots. Grouillez-vous, si vous mettez trop de temps, les Greenternautes vont partir sur un réseau social qui consomme plus d’énergie et qui laisse les rumeurs se propager… Et ça, c’est le retour au vieil Internet, et on n’en veut pas ! Vous pouvez vous connecter en tant qu'administrateur avec mon mot de passe : knootadmin"
    },
    {
        message:  "J’ai l’impression que vous avez masqué une bonne quantité de faux knoots, je vous félicite, vraiment c’est bien joué ! Et du coup, maintenant, j’aimerais que vous publiiez un knoot pour expliquer la situation aux gens sur le réseau social. Et mettez une photo pour appuyer votre message !",
    },
    {
        message:  "Je pense que vous avez reçu mes messages précédents, parce que je viens de voir votre knoot, et franchement… Il est parfait ! Bravo ! Par contre, regardez bien combien de personnes vous avez touchées avec votre knoot, ça nous permettra de désamorcer le virus plus tard.",
    }
];

export const uploadDataImg = [
    {
        url: "catfunny.jpg",
        size: "3Mo",
    },
    {
        url: "catsmile.jpg",
        size: "545Ko",
    },
    {
        url: "thumbsupcat.png",
        size: "325Ko",
    },
]