import * as React from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import KnootContext from "./KContext";

import GavelIcon from '@mui/icons-material/Gavel';

//Assigne une couleur d'avatar à une lettre
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

//CSS de l'avatar
function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.charAt(0).toUpperCase()}`,
    };
}

//Fonction d'alerte propre à Material UI + CSS (Counter-Strike Source)
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Tweet(props) {
    const [likes, setLikes] = React.useState(parseInt(props.likes)); //Nombres de likes d'un tweet
    const [isliked, setLiked] = React.useState(false); //Tweet Liké ?
    const [openSnack, setOpenSnack] = React.useState(false); //Notification d'erreur ou autre
    const [anchorEl, setAnchorEl] = React.useState(null); //Ancre qui permet de placer le menu de banissement

    const { checkBan } = React.useContext(KnootContext);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    //Dopamine energizer 3000
    const toogleLike = () => {
        if (isliked) {
            setLiked(false);
            setLikes(likes - 1);
        } else {
            setLiked(true);
            setLikes(likes + 1);
        }
    }

    //Bouton de partage
    const handleClickShare = () => {
        setOpenSnack(true);
    };

    const handleCloseShare = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    //Si on ban (démocratiquement) une image elle apparait floutée
    const imageBanned = () => {
        if (props.image) {
            if (props.iscontentBanned) {
                return (<CardMedia
                    sx={{filter: "blur(1px)"}}
                    component="img"
                    image={`/img/tweet_img/banned.png`}
                    alt={"Banned_Image"}
                />);
            }
            return (<CardMedia
                component="img"
                image={`/img/tweet_img/${props.image}`}
                alt={props.image}
            />);
        }
        else {
            return false;
        }
    }

    //Regarde si le pseudo à ban est le bon
    const handleClickBan = () => {
        checkBan(props.pseudo);
    }


    return (
        <>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseShare}>
                <Alert onClose={handleCloseShare} severity="success" sx={{ width: '100%' }}>
                    Lien copié !
                </Alert>
            </Snackbar>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "settings-button",
                }}
            >
                <MenuItem onClick={handleClickBan}>
                    <ListItemIcon>
                        <GavelIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{`Bannir ${props.pseudo}`}</ListItemText>
                </MenuItem>
            </Menu>
            <Card sx={{ maxWidth: 800 }}>
                <CardHeader
                    avatar={props.pp ? <Avatar alt={props.pp} src={`/img/tweet_img/${props.pp}`} /> :
                        <Avatar {...stringAvatar(props.pseudo)} />
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            aria-controls={open ? 'settings-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={props.pseudo}
                    subheader={props.date}
                />
                {
                    imageBanned()
                }
                <CardContent>
                    <Typography sx={props.iscontentBanned ? { color: "red", filter: "blur(1px)" } : {}} variant="body2" color="text.secondary">
                        {props.content}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton onClick={toogleLike} aria-label="add to favorites">
                        <FavoriteIcon sx={isliked ? { color: "red" } : {}} />
                    </IconButton>
                    <Typography variant="body2">
                        {likes}
                    </Typography>
                    <IconButton onClick={handleClickShare} aria-label="share">
                        <ShareIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </>
    );
}