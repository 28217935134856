//Tous les Icons utilisés
// import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
// import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
// import DiscFullIcon from '@mui/icons-material/DiscFull';
// import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
// import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';
// import BluetoothAudioIcon from '@mui/icons-material/BluetoothAudio';
// import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
// import LightbulbIcon from '@mui/icons-material/Lightbulb';
// import BluetoothDriveIcon from '@mui/icons-material/BluetoothDrive';
// import CallIcon from '@mui/icons-material/Call';
// import CoffeeMakerIcon from '@mui/icons-material/CoffeeMaker';
// import FluorescentIcon from '@mui/icons-material/Fluorescent';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import HeadphonesIcon from '@mui/icons-material/Headphones';
// import AlbumIcon from '@mui/icons-material/Album';
// import RouterIcon from '@mui/icons-material/Router';
// import UsbIcon from '@mui/icons-material/Usb';
// import ComputerIcon from '@mui/icons-material/Computer';
// import MicrowaveIcon from '@mui/icons-material/Microwave';

import ampoule from '../components/code/icons/ampoule.svg'; // PAS CODE
import cassette from '../components/code/icons/cassette.svg'; // PAS CODE
import extincteur from '../components/code/icons/extincteur.svg'; // PAS CODE 
import pile from '../components/code/icons/pile.svg'; // PAS CODE
import raclette from '../components/code/icons/raclette.svg'; // PAS CODE
import secheCheveux from '../components/code/icons/secheCheveux.svg';  // PAS CODE
import visseuse from '../components/code/icons/visseuse.svg'; // PAS CODE


import enseigne from '../components/code/icons/enseigne.svg'; // CODE
import bluetooth from '../components/code/icons/bluetooth.svg'; // CODE
import laveLinge from '../components/code/icons/laveLinge.svg'; // CODE
import playstation from '../components/code/icons/playstation.svg'; // CODE
import montre from '../components/code/icons/montre.svg'; //CODE
import tv from '../components/code/icons/tv.svg'; // CODE
import voiture from '../components/code/icons/voiture.svg'; // CODE




export const cadres = [
    {
        url: "/img/codeprogra_img/ada.jpg",
        w: "60%",
        text: "ADA",
        target: "A"
    },
    {
        url: "/img/codeprogra_img/c.jpg",
        w: "65%",
        text: "C",
        target: "C",
    },
    {
        url: "/img/codeprogra_img/clu.jpg",
        w: "40%",
        text: "CLU",
        target: "L",
    },
    {
        url: "/img/codeprogra_img/cobol.jpg",
        w: "45%",
        text: "COBOL",
        target: "O",
    },
    {
        url: "/img/codeprogra_img/java.jpg",
        w: "60%",
        text: "JAVA",
        target: "J",
    },
    {
        url: "/img/codeprogra_img/javascript.jpg",
        w: "60%",
        text: "JAVASCRIPT",
        target: "S",
    },
    {
        url: "/img/codeprogra_img/php.jpg",
        w: "50%",
        text: "PHP",
        target: "P"
    },
    {
        url: "/img/codeprogra_img/python.jpg",
        w: "65%",
        text: "PYTHON",
        target: "Y",
    },
]

// export const objCodePasCode = [//Objet comportant les objets de l'énigme [id de l'objet (unique), la réponse attendu (true=CODE, false=PAS CODE), logo de l'item]
// { id: "ConsoleDeJeu", ResponseValue: true, label: "Console de jeu", logo: <VideogameAssetIcon /> },
// { id: "Pile", ResponseValue: false, label: "Pile", logo: <BatteryChargingFullIcon /> },
// { id: "MachineALaver", ResponseValue: true, label: "Machine à laver", logo: <LocalLaundryServiceIcon /> },
// { id: "TelephoneACadran", ResponseValue: false, label: "Telephone à cadran", logo: <CallIcon /> },
// { id: "EnceinteBluetooth", ResponseValue: true, label: "Enceinte Bluetooth", logo: <BluetoothAudioIcon /> },
// { id: "USB", ResponseValue: false, label: "USB", logo: <UsbIcon /> },
// { id: "MicroOndes", ResponseValue: true, label: "Micro-Ondes", logo: <MicrowaveIcon /> },
// { id: "Television", ResponseValue: true, label: "Télévision", logo: <PersonalVideoIcon /> },
// { id: "CafetiereCapsule", ResponseValue: true, label: "Cafetière à capsule", logo: <CoffeeMakerIcon /> },
// { id: "PlatineVinyle", ResponseValue: false, label: "Platine vinyle", logo: <DiscFullIcon /> },
// { id: "VoitureConnectee", ResponseValue: true, label: "Voiture connectee", logo: <BluetoothDriveIcon /> },
// { id: "EcouteurBluetooth", ResponseValue: true, label: "Ecouteur Bluetooth", logo: <HeadphonesIcon /> },
// { id: "TelephonePortable", ResponseValue: true, label: "Telephone portable", logo: <PhoneAndroidIcon /> },
// { id: "AmpouleHalogene", ResponseValue: false, label: "Ampoule halogene", logo: <LightbulbIcon /> },
// { id: "Fusee", ResponseValue: true, label: "Fusée", logo: <RocketLaunchIcon /> },
// { id: "Ordinateur", ResponseValue: true, label: "Ordinateur", logo: <ComputerIcon /> },
// { id: "CD", ResponseValue: false, label: "CD", logo: <AlbumIcon /> },
// { id: "Chargeur", ResponseValue: false, label: "Chargeur", logo: <ElectricalServicesIcon /> },
// { id: "BoxInternet", ResponseValue: true, label: "Box internet", logo: <RouterIcon /> },
// { id: "EnseigneLumineuse", ResponseValue: true, label: "Enseigne lumineuse", logo: <FluorescentIcon /> },
// ]

export const objCodePasCode = [
    { id: "SmartTv", ResponseValue: true, label: "Smart TV", logo: tv },
    { id: "VisseuseACable", ResponseValue: false, label: "Visseuse à câble", logo: visseuse },
    { id: "MontreConnectee", ResponseValue: true, label: "Montre à hologramme", logo: montre },
    { id: "SecheCheveux", ResponseValue: false, label: "Sèche-cheveux", logo: secheCheveux },
    { id: "VoitureConnectee", ResponseValue: true, label: "Citroën ME hydrogène", logo: voiture },
    { id: "AmpouleHalogene", ResponseValue: false, label: "Ampoule halogène", logo: ampoule },
    { id: "EnseigneLumineuse", ResponseValue: true, label: "Enseigne de pharmacie", logo: enseigne },
    { id: "Cassette", ResponseValue: false, label: "Cassette audio", logo: cassette },
    { id: "EnceinteBluetooth", ResponseValue: true, label: "Enceinte Bluetooth", logo: bluetooth },
    { id: "Extincteur", ResponseValue: false, label: "Extincteur", logo: extincteur },
    { id: "LaveLinge", ResponseValue: true, label: "Lave-linge programmable", logo: laveLinge },
    { id: "Pile", ResponseValue: false, label: "Pile alcaline", logo: pile },
    { id: "ConsoleDeJeu", ResponseValue: true, label: "Playstation 10", logo: playstation },
    { id: "Raclette", ResponseValue: false, label: "Appareil à raclette", logo: raclette }
  ]

export const caracteresCibles = ["y","Y"];