export const colors = ['red', 'green', 'blue', 'yellow', 'purple', 'cyan', 'orange', 'pink']//couleur des colonnes
export const colors2 = ['#5e52a4', '#ed207b', '#00adef', '#f0592a', '#2bb572', '#24aae1', '#f03f37', '#fdc368']//couleur des H1,H2,H3,H4,H5,H6,H7,H8 

export const g1data = [//information 1 er graphique
    {zone: "Afrique", quantity: 3900000},
    {zone: "Amérique centrale", quantity: 700000},
    {zone: "Amérique du Nord", quantity: 200000000},
    {zone: "Amérique du Sud", quantity: 5000000},
    {zone: "Asie", quantity: 9500000},
    {zone: "Europe", quantity: 140000000},
    {zone: "Moyen-Orient", quantity: 5000000},
    {zone: "Océanie", quantity: 15000000}
]

export const g2data = [//information 2 éme graphique
    {zone: "Afrique",h1:80,h2:100,h3:90,h4:90,h5:100,h6:100,h7:88,h8:100},
    {zone: "Amérique centrale",h1:100,h2:88,h3:100,h4:81,h5:100,h6:84,h7:100,h8:88},
    {zone: "Amérique du Nord",h1:100,h2:81,h3:100,h4:100,h5:78,h6:82,h7:100,h8:88},
    {zone: "Amérique du Sud",h1:80,h2:100,h3:100,h4:58,h5:100,h6:69,h7:100,h8:100},
    {zone: "Asie",h1:100,h2:85,h3:80,h4:82,h5:89,h6:85,h7:80,h8:100},
    {zone: "Europe",h1:88,h2:100,h3:100,h4:100,h5:82,h6:100,h7:85,h8:89},
    {zone: "Moyen-Orient",h1:100,h2:100,h3:81,h4:75,h5:83,h6:88,h7:100,h8:88},
    {zone: "Océanie",h1:100,h2:100,h3:88,h4:100,h5:78,h6:100,h7:88,h8:100},
]

export const g3data = [//information 3 éme graphique
    {zone: "Afrique",h1:100,h2:100,h3:90,h4:100,h5:100,h6:100,h7:100,h8:100},
    {zone: "Amérique centrale",h1:100,h2:100,h3:100,h4:100,h5:100,h6:89,h7:100,h8:89},
    {zone: "Amérique du Nord",h1:100,h2:100,h3:100,h4:100,h5:89,h6:100,h7:86,h8:100},
    {zone: "Amérique du Sud",h1:100,h2:100,h3:100,h4:100,h5:100,h6:80,h7:100,h8:100},
    {zone: "Asie",h1:100,h2:100,h3:84,h4:100,h5:100,h6:100,h7:100,h8:100},
    {zone: "Europe",h1:100,h2:100,h3:100,h4:100,h5:100,h6:100,h7:100,h8:100},
    {zone: "Moyen-Orient",h1:100,h2:100,h3:83,h4:86,h5:100,h6:88,h7:100,h8:100},
    {zone: "Océanie",h1:81,h2:100,h3:100,h4:100,h5:89,h6:100,h7:89,h8:100},
]

export const columns = [//nom des colonnes du tableau
    {
        id: 'id',
        label: 'ID',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'est_sain',
        label: 'Sain ?',
    },
    {
        id: 'date_construction',
        label: 'Date de construction',
    },
    {
        id: 'commentaire',
        label: 'Commentaire',
        minWidth: 200,
    },
    {
        id: 'lat',
        label: 'Latitude',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'lng',
        label: 'Longitude',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'zone',
        label: 'Zone',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h1',
        label: 'H1',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h2',
        label: 'H2',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h3',
        label: 'H3',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h4',
        label: 'H4',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h5',
        label: 'H5',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h6',
        label: 'H6',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h7',
        label: 'H7',
        format: (value) => value.toLocaleString('fr-FR'),
    },
    {
        id: 'h8',
        label: 'H8',
        format: (value) => value.toLocaleString('fr-FR'),
    },
];

export const serveurs = //données du tableau
[{id:1,est_sain:"Non",date_construction:"12/07/2002",commentaire:null,lat:38.0434058,lng:-121.2969674,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:2,est_sain:"Non",date_construction:"03/09/2017",commentaire:null,lat:49.243003,lng:2.510652,zone:5,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:3,est_sain:"Non",date_construction:"11/11/2015",commentaire:null,lat:11.9227104,lng:37.6998424,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:4,est_sain:"Oui",date_construction:"02/11/2003",commentaire:null,lat:4.9211621,lng:6.2747734,zone:8,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:5,est_sain:"Oui",date_construction:"24/06/2010",commentaire:null,lat:39.508988,lng:46.343889,zone:2,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:6,est_sain:"Oui",date_construction:"31/05/2000",commentaire:null,lat:49.8156816,lng:18.8067375,zone:6,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:7,est_sain:"Oui",date_construction:"21/08/2003",commentaire:null,lat:-7.5002292,lng:107.9739244,zone:5,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:8,est_sain:"Non",date_construction:"20/09/2004",commentaire:null,lat:45.207675,lng:37.2884784,zone:1,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:9,est_sain:"Oui",date_construction:"24/05/2020",commentaire:null,lat:33.770447,lng:117.624455,zone:3,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:10,est_sain:"Oui",date_construction:"09/08/2004",commentaire:null,lat:38.7418853,lng:117.5352694,zone:4,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:11,est_sain:"Non",date_construction:"29/09/2003",commentaire:null,lat:-34.7725956,lng:-58.6529983,zone:4,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:12,est_sain:"Non",date_construction:"13/07/1993",commentaire:null,lat:24.1919524,lng:89.9681979,zone:3,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:13,est_sain:"Non",date_construction:"01/12/2013",commentaire:null,lat:14.3012259,lng:122.4328888,zone:2,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:14,est_sain:"Oui",date_construction:"28/07/2010",commentaire:null,lat:12.074325,lng:-1.3421032,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:15,est_sain:"Oui",date_construction:"04/06/1995",commentaire:null,lat:48.9398644,lng:16.8996364,zone:4,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:16,est_sain:"Non",date_construction:"16/06/2001",commentaire:"Maintenance à prévoir en décembre 2021.",lat:-22.250939,lng:-45.928795,zone:5,h1:"Non",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Non",h7:"Non",h8:"Oui"},
{id:17,est_sain:"Non",date_construction:"21/11/1996",commentaire:null,lat:10.5922194,lng:123.4845632,zone:1,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:18,est_sain:"Non",date_construction:"10/12/2013",commentaire:"Déconnexion fréquente, trouver provenance.",lat:8.1113884,lng:123.8961588,zone:2,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:19,est_sain:"Non",date_construction:"14/10/2008",commentaire:null,lat:-28.4134906,lng:-64.7629972,zone:5,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:20,est_sain:"Oui",date_construction:"21/12/2001",commentaire:null,lat:29.859178,lng:106.410852,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:21,est_sain:"Non",date_construction:"28/12/2012",commentaire:null,lat:53.0637214,lng:26.6392615,zone:7,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:22,est_sain:"Non",date_construction:"14/07/2010",commentaire:null,lat:41.9437159,lng:20.006257,zone:3,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:23,est_sain:"Oui",date_construction:"27/12/2001",commentaire:null,lat:49.3304143,lng:17.2726367,zone:3,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:24,est_sain:"Non",date_construction:"20/11/2005",commentaire:null,lat:16.0501738,lng:103.5899733,zone:2,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:25,est_sain:"Non",date_construction:"07/01/2009",commentaire:null,lat:39.2259226,lng:23.2195437,zone:5,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:26,est_sain:"Non",date_construction:"14/01/2014",commentaire:null,lat:31.912048,lng:119.783935,zone:4,h1:"Non",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:27,est_sain:"Oui",date_construction:"11/06/2019",commentaire:null,lat:50.7917257,lng:21.2322042,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:28,est_sain:"Oui",date_construction:"06/03/2002",commentaire:null,lat:43.9336277,lng:42.5107159,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:29,est_sain:"Non",date_construction:"21/08/2009",commentaire:null,lat:44.150897,lng:17.7905271,zone:3,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:30,est_sain:"Non",date_construction:"21/07/2006",commentaire:null,lat:-7.347756,lng:108.8818859,zone:6,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:31,est_sain:"Non",date_construction:"18/07/2009",commentaire:"Plus petit serveur du monde.",lat:50.6261682,lng:13.9528289,zone:3,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:32,est_sain:"Non",date_construction:"20/02/1997",commentaire:null,lat:14.2116743,lng:121.1551767,zone:6,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:33,est_sain:"Oui",date_construction:"08/06/1999",commentaire:null,lat:40.256729,lng:115.474295,zone:3,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:34,est_sain:"Non",date_construction:"31/07/2007",commentaire:null,lat:32.509155,lng:120.127934,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:35,est_sain:"Oui",date_construction:"27/10/2002",commentaire:null,lat:23.681533,lng:110.584168,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:36,est_sain:"Non",date_construction:"21/03/1994",commentaire:null,lat:52.5643,lng:31.1364,zone:6,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:37,est_sain:"Oui",date_construction:"30/01/1998",commentaire:null,lat:44.819386,lng:4.596042,zone:2,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:38,est_sain:"Oui",date_construction:"12/05/1998",commentaire:null,lat:47.747102,lng:122.831186,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:39,est_sain:"Non",date_construction:"02/03/2012",commentaire:null,lat:-13.53195,lng:-71.9674626,zone:5,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:40,est_sain:"Oui",date_construction:"02/06/2013",commentaire:"Serveur dans le sous-sol 3001.",lat:30.5731514,lng:-7.2032754,zone:6,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:41,est_sain:"Non",date_construction:"09/07/2018",commentaire:null,lat:29.099294,lng:119.692696,zone:5,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:42,est_sain:"Oui",date_construction:"02/01/1998",commentaire:null,lat:35.368795,lng:35.9206549,zone:2,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:43,est_sain:"Oui",date_construction:"20/01/2007",commentaire:null,lat:-8.1669161,lng:112.5991372,zone:6,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:44,est_sain:"Non",date_construction:"17/04/2006",commentaire:"Test de micro-serveur.",lat:39.9474817,lng:116.4689261,zone:8,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:45,est_sain:"Oui",date_construction:"03/04/2001",commentaire:null,lat:41.603085,lng:46.638308,zone:6,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:46,est_sain:"Non",date_construction:"22/01/2013",commentaire:null,lat:8.235581,lng:123.8545713,zone:4,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:47,est_sain:"Non",date_construction:"16/01/2004",commentaire:null,lat:55.55,lng:45.9166668,zone:4,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:48,est_sain:"Oui",date_construction:"19/03/2000",commentaire:null,lat:-6.81357,lng:30.4326,zone:5,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:49,est_sain:"Non",date_construction:"15/10/1993",commentaire:null,lat:28.048158,lng:109.723392,zone:5,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Oui"},
{id:50,est_sain:"Non",date_construction:"12/04/1999",commentaire:null,lat:36.962751,lng:100.901228,zone:3,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:51,est_sain:"Oui",date_construction:"25/12/2018",commentaire:null,lat:59.4248225,lng:56.6216121,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:52,est_sain:"Non",date_construction:"31/07/1995",commentaire:null,lat:59.8037463,lng:30.6044813,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:53,est_sain:"Non",date_construction:"11/06/1996",commentaire:null,lat:48.1406371,lng:38.9367364,zone:3,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:54,est_sain:"Non",date_construction:"30/09/2009",commentaire:null,lat:11.0980639,lng:107.678501,zone:4,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:55,est_sain:"Non",date_construction:"16/09/1994",commentaire:null,lat:7.145296,lng:80.568823,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:56,est_sain:"Non",date_construction:"02/12/2002",commentaire:null,lat:38.508271,lng:-28.51476,zone:4,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:57,est_sain:"Oui",date_construction:"31/10/2001",commentaire:null,lat:-7.1913,lng:107.8902,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:58,est_sain:"Non",date_construction:"16/11/2017",commentaire:null,lat:45.3218651,lng:17.7681327,zone:4,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:59,est_sain:"Oui",date_construction:"16/12/2014",commentaire:null,lat:59.2896794,lng:39.6767475,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:60,est_sain:"Oui",date_construction:"01/08/2009",commentaire:null,lat:31.4627186,lng:-7.6080892,zone:8,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:61,est_sain:"Oui",date_construction:"29/09/1998",commentaire:null,lat:-27.9918685,lng:26.7241905,zone:6,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:62,est_sain:"Oui",date_construction:"28/11/1995",commentaire:null,lat:61.2022597,lng:22.2731105,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:63,est_sain:"Oui",date_construction:"21/02/2008",commentaire:null,lat:-8.4963229,lng:123.3766669,zone:4,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:64,est_sain:"Non",date_construction:"20/11/1994",commentaire:null,lat:52.2542902,lng:19.034146,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:65,est_sain:"Non",date_construction:"01/11/2015",commentaire:null,lat:12.2570417,lng:109.04532,zone:5,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:66,est_sain:"Non",date_construction:"24/06/2019",commentaire:null,lat:47.8890778,lng:-2.8357471,zone:4,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:67,est_sain:"Non",date_construction:"02/07/2009",commentaire:null,lat:11.1856085,lng:124.9202302,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:68,est_sain:"Oui",date_construction:"19/08/2001",commentaire:null,lat:50.46678,lng:-104.41778,zone:2,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:69,est_sain:"Non",date_construction:"02/11/2014",commentaire:null,lat:49.5936213,lng:13.1013031,zone:8,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:70,est_sain:"Oui",date_construction:"02/03/2012",commentaire:null,lat:19.75,lng:-104.016667,zone:5,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:71,est_sain:"Non",date_construction:"24/01/2002",commentaire:null,lat:-29.2581646,lng:-51.5289168,zone:8,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:72,est_sain:"Oui",date_construction:"07/02/2017",commentaire:null,lat:40.0231325,lng:44.5918627,zone:3,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:73,est_sain:"Non",date_construction:"01/03/2017",commentaire:"v2 du serveur.",lat:29.164409,lng:116.78956,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Oui",h8:"Oui"},
{id:74,est_sain:"Non",date_construction:"15/05/2004",commentaire:null,lat:56.412767,lng:16.0115323,zone:8,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:75,est_sain:"Oui",date_construction:"04/06/2005",commentaire:null,lat:56.6338771,lng:47.8109854,zone:6,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:76,est_sain:"Non",date_construction:"23/09/1999",commentaire:null,lat:40.0580479,lng:116.3333034,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Oui",h8:"Oui"},
{id:77,est_sain:"Oui",date_construction:"12/04/1999",commentaire:null,lat:36.13521,lng:107.382043,zone:6,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:78,est_sain:"Oui",date_construction:"21/05/2013",commentaire:null,lat:-30.7102921,lng:27.2382163,zone:2,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:79,est_sain:"Non",date_construction:"07/06/2000",commentaire:null,lat:10.3187555,lng:123.906446,zone:1,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:80,est_sain:"Non",date_construction:"23/12/2017",commentaire:null,lat:24.8658422,lng:121.2969674,zone:5,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:81,est_sain:"Non",date_construction:"03/01/2011",commentaire:null,lat:56.9940675,lng:13.209918,zone:6,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:82,est_sain:"Non",date_construction:"10/05/2016",commentaire:null,lat:23.3854292,lng:113.4895468,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:83,est_sain:"Oui",date_construction:"11/02/1994",commentaire:"Trop vieux, à changer.",lat:40.5742298,lng:48.3896404,zone:7,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:84,est_sain:"Non",date_construction:"16/12/2014",commentaire:null,lat:38.0068597,lng:-8.385532,zone:1,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:85,est_sain:"Oui",date_construction:"13/02/2003",commentaire:null,lat:38.400112,lng:114.866414,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:86,est_sain:"Non",date_construction:"13/09/1998",commentaire:null,lat:13.1870854,lng:123.4572574,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:87,est_sain:"Oui",date_construction:"22/12/2003",commentaire:null,lat:40.8782107,lng:14.2657584,zone:4,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:88,est_sain:"Oui",date_construction:"16/09/2004",commentaire:null,lat:39.953041,lng:116.371207,zone:8,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:89,est_sain:"Oui",date_construction:"18/02/2006",commentaire:null,lat:-7.1582623,lng:107.9235563,zone:1,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:90,est_sain:"Oui",date_construction:"07/05/2015",commentaire:null,lat:45.7249153,lng:-73.4913241,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:91,est_sain:"Non",date_construction:"12/12/2001",commentaire:null,lat:17.8846183,lng:-77.5950623,zone:3,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:92,est_sain:"Non",date_construction:"04/12/2013",commentaire:null,lat:28.888555,lng:120.047651,zone:8,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:93,est_sain:"Non",date_construction:"15/06/1999",commentaire:null,lat:27.2711,lng:116.580186,zone:2,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:94,est_sain:"Oui",date_construction:"07/10/2005",commentaire:null,lat:3.191408,lng:113.088634,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:95,est_sain:"Non",date_construction:"10/12/1994",commentaire:null,lat:47.2747492,lng:-1.4666288,zone:1,h1:"Non",h2:"Non",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:96,est_sain:"Non",date_construction:"22/08/2008",commentaire:"Suspicion une panne.",lat:53.5754959,lng:10.0807485,zone:8,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:97,est_sain:"Oui",date_construction:"28/01/1997",commentaire:null,lat:30.565162,lng:114.274759,zone:6,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:98,est_sain:"Oui",date_construction:"10/10/2011",commentaire:null,lat:30.39194,lng:114.894843,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:99,est_sain:"Non",date_construction:"04/04/1998",commentaire:null,lat:44.9447526,lng:4.8413855,zone:5,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:100,est_sain:"Oui",date_construction:"02/09/2008",commentaire:null,lat:45.0075322,lng:19.8227166,zone:5,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:101,est_sain:"Oui",date_construction:"24/06/1993",commentaire:null,lat:30.45,lng:-91.15,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:102,est_sain:"Oui",date_construction:"27/06/2015",commentaire:null,lat:53.76686,lng:-112.80195,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:103,est_sain:"Non",date_construction:"15/07/2002",commentaire:"Configurer le pare-feu.",lat:46.083333,lng:103.833333,zone:1,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:104,est_sain:"Non",date_construction:"06/02/2002",commentaire:null,lat:-11.7806236,lng:-75.4869033,zone:4,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:105,est_sain:"Oui",date_construction:"13/07/2014",commentaire:null,lat:33.8761249,lng:7.8803236,zone:7,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:106,est_sain:"Non",date_construction:"02/01/2003",commentaire:null,lat:46.8117208,lng:33.4901963,zone:4,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Oui"},
{id:107,est_sain:"Oui",date_construction:"10/01/2016",commentaire:null,lat:48.85786,lng:16.8938239,zone:3,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:108,est_sain:"Oui",date_construction:"06/02/2006",commentaire:null,lat:22.7627589,lng:-81.4471254,zone:3,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:109,est_sain:"Non",date_construction:"03/08/2003",commentaire:null,lat:31.93957,lng:121.012416,zone:3,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:110,est_sain:"Non",date_construction:"03/08/2015",commentaire:"Serveur mail de GT.",lat:51.3412504,lng:32.8797802,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:111,est_sain:"Non",date_construction:"30/11/1997",commentaire:null,lat:26.015959,lng:116.602435,zone:5,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:112,est_sain:"Oui",date_construction:"05/08/2003",commentaire:null,lat:38.6223709,lng:-8.9867334,zone:8,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:113,est_sain:"Non",date_construction:"23/11/1996",commentaire:null,lat:61.6833669,lng:96.3806203,zone:5,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:114,est_sain:"Oui",date_construction:"16/07/2016",commentaire:null,lat:36.7498896,lng:51.014934,zone:5,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Oui",h8:"Oui"},
{id:115,est_sain:"Oui",date_construction:"27/02/2010",commentaire:null,lat:37.3833017,lng:55.5025595,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:116,est_sain:"Non",date_construction:"16/01/1997",commentaire:null,lat:40.4938437,lng:-8.4743511,zone:2,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:117,est_sain:"Oui",date_construction:"10/03/1994",commentaire:null,lat:51.5141668,lng:-0.1331854,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:118,est_sain:"Non",date_construction:"04/03/1998",commentaire:null,lat:-7.0954176,lng:110.6909294,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:119,est_sain:"Oui",date_construction:"30/03/2013",commentaire:null,lat:49.5658236,lng:18.814406,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:120,est_sain:"Non",date_construction:"07/07/1998",commentaire:"Panne récurrente.",lat:-6.162959,lng:35.7516069,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:121,est_sain:"Oui",date_construction:"28/06/1995",commentaire:null,lat:-12.95689,lng:-74.023193,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:122,est_sain:"Oui",date_construction:"02/03/2000",commentaire:null,lat:-33.4856642,lng:-70.727931,zone:3,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:123,est_sain:"Oui",date_construction:"19/07/2006",commentaire:null,lat:38.0435902,lng:23.9783441,zone:5,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:124,est_sain:"Non",date_construction:"30/03/2020",commentaire:null,lat:48.6277459,lng:2.4381665,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:125,est_sain:"Non",date_construction:"09/06/2007",commentaire:null,lat:58.7243769,lng:15.0017895,zone:3,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:126,est_sain:"Oui",date_construction:"26/08/2003",commentaire:null,lat:21.459233,lng:101.564635,zone:1,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:127,est_sain:"Non",date_construction:"06/03/2003",commentaire:null,lat:27.888284,lng:111.6502,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:128,est_sain:"Non",date_construction:"15/04/2020",commentaire:null,lat:-7.3887,lng:108.6759,zone:6,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:129,est_sain:"Oui",date_construction:"03/03/2018",commentaire:"Impossibilité de se connecter depuis la Chine.",lat:29.274384,lng:90.253717,zone:3,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:130,est_sain:"Oui",date_construction:"25/04/1998",commentaire:null,lat:48.8588029,lng:24.9968117,zone:4,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:131,est_sain:"Non",date_construction:"14/09/2017",commentaire:null,lat:7.28361,lng:125.84222,zone:1,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:132,est_sain:"Non",date_construction:"24/03/2000",commentaire:"Origine du bug de Starbucks (2015).",lat:36.650038,lng:101.766228,zone:8,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:133,est_sain:"Oui",date_construction:"09/09/2002",commentaire:null,lat:-32.9377962,lng:-60.6961061,zone:6,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:134,est_sain:"Non",date_construction:"22/06/1995",commentaire:null,lat:41.2717724,lng:-8.2890328,zone:6,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:135,est_sain:"Oui",date_construction:"29/05/1998",commentaire:null,lat:51.441874,lng:6.886548,zone:5,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:136,est_sain:"Oui",date_construction:"09/10/2013",commentaire:null,lat:40.7843506,lng:22.0874496,zone:3,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:137,est_sain:"Non",date_construction:"15/06/2012",commentaire:null,lat:40.7477848,lng:-8.0752858,zone:4,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:138,est_sain:"Oui",date_construction:"18/07/2011",commentaire:null,lat:36.6740953,lng:46.570133,zone:8,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:139,est_sain:"Non",date_construction:"11/07/1993",commentaire:null,lat:46.3349544,lng:2.789104,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:140,est_sain:"Non",date_construction:"21/03/2003",commentaire:null,lat:0.0351644,lng:36.3642919,zone:8,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:141,est_sain:"Oui",date_construction:"08/04/2000",commentaire:null,lat:41.5193418,lng:-8.5747445,zone:3,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:142,est_sain:"Non",date_construction:"31/05/1994",commentaire:null,lat:31.77,lng:-106.43,zone:5,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:143,est_sain:"Oui",date_construction:"22/09/2012",commentaire:null,lat:32.5518151,lng:51.5307789,zone:1,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Oui"},
{id:144,est_sain:"Non",date_construction:"14/07/2009",commentaire:"Pas load-balancé.",lat:-2.1667354,lng:101.0699339,zone:6,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Oui"},
{id:145,est_sain:"Oui",date_construction:"24/02/2004",commentaire:null,lat:-42.7700601,lng:-65.0306302,zone:7,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:146,est_sain:"Non",date_construction:"20/04/2009",commentaire:null,lat:37.9773385,lng:140.1850334,zone:4,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:147,est_sain:"Non",date_construction:"10/07/2013",commentaire:null,lat:36.837625,lng:112.864561,zone:8,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:148,est_sain:"Non",date_construction:"26/06/2017",commentaire:null,lat:-7.0606502,lng:108.9264524,zone:3,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:149,est_sain:"Non",date_construction:"09/03/2009",commentaire:null,lat:22.1059284,lng:92.086133,zone:1,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:150,est_sain:"Oui",date_construction:"03/05/1999",commentaire:null,lat:-7.003074,lng:108.0035567,zone:6,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:151,est_sain:"Non",date_construction:"06/06/2007",commentaire:null,lat:41.4564504,lng:126.6441618,zone:2,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:152,est_sain:"Oui",date_construction:"10/06/2013",commentaire:null,lat:-10.2156554,lng:-37.4212807,zone:8,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:153,est_sain:"Oui",date_construction:"25/12/2008",commentaire:null,lat:14.8182209,lng:-88.9377949,zone:8,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:154,est_sain:"Oui",date_construction:"26/07/1999",commentaire:null,lat:29.117096,lng:110.479191,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Oui"},
{id:155,est_sain:"Oui",date_construction:"27/12/2017",commentaire:"Non accessible depuis la Corse.",lat:57.056336,lng:64.6282995,zone:1,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:156,est_sain:"Oui",date_construction:"14/01/2000",commentaire:null,lat:37.120983,lng:114.671935,zone:3,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:157,est_sain:"Non",date_construction:"24/06/2015",commentaire:null,lat:-23.1770273,lng:-46.4970116,zone:1,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:158,est_sain:"Non",date_construction:"30/12/2013",commentaire:null,lat:38.7240446,lng:-77.1082444,zone:6,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:159,est_sain:"Non",date_construction:"10/09/2010",commentaire:null,lat:8.235581,lng:123.8545713,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:160,est_sain:"Non",date_construction:"04/11/2017",commentaire:null,lat:38.3179046,lng:116.8578425,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:161,est_sain:"Non",date_construction:"30/09/1998",commentaire:null,lat:-12.67442,lng:-75.325157,zone:2,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:162,est_sain:"Non",date_construction:"31/08/2010",commentaire:null,lat:32.099905,lng:118.031463,zone:4,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:163,est_sain:"Non",date_construction:"04/09/2008",commentaire:null,lat:-19.90625,lng:47.2342299,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:164,est_sain:"Non",date_construction:"08/06/2017",commentaire:null,lat:45.716147,lng:-73.5471039,zone:5,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:165,est_sain:"Non",date_construction:"01/09/1996",commentaire:null,lat:-25.4079504,lng:-52.4134746,zone:2,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:166,est_sain:"Oui",date_construction:"20/11/2003",commentaire:null,lat:41.1846097,lng:80.3038321,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:167,est_sain:"Oui",date_construction:"22/10/2004",commentaire:null,lat:-8.3628,lng:123.3026,zone:3,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:168,est_sain:"Oui",date_construction:"18/12/2018",commentaire:null,lat:-10.0533,lng:120.4839,zone:2,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:169,est_sain:"Non",date_construction:"21/05/2010",commentaire:null,lat:48.6011478,lng:-1.9817089,zone:1,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:170,est_sain:"Oui",date_construction:"08/07/1998",commentaire:null,lat:14.6639812,lng:121.022408,zone:3,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Non",h8:"Oui"},
{id:171,est_sain:"Oui",date_construction:"01/08/2005",commentaire:null,lat:50.5848682,lng:15.4126394,zone:7,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Non"},
{id:172,est_sain:"Oui",date_construction:"01/04/2009",commentaire:null,lat:-8.759734,lng:121.8306397,zone:1,h1:"Oui",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:173,est_sain:"Non",date_construction:"06/10/2013",commentaire:null,lat:55.8697739,lng:48.5236711,zone:1,h1:"Oui",h2:"Oui",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:174,est_sain:"Oui",date_construction:"02/02/2000",commentaire:null,lat:0.355636,lng:37.5833061,zone:4,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:175,est_sain:"Non",date_construction:"28/09/2004",commentaire:null,lat:-16.1073563,lng:46.6463975,zone:5,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:176,est_sain:"Non",date_construction:"09/06/2020",commentaire:null,lat:-11.4346353,lng:-41.3803558,zone:1,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:177,est_sain:"Non",date_construction:"20/08/2009",commentaire:null,lat:49.5458457,lng:19.0802389,zone:7,h1:"Non",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:178,est_sain:"Oui",date_construction:"26/04/2002",commentaire:null,lat:36.639033,lng:114.462061,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:179,est_sain:"Non",date_construction:"14/02/2009",commentaire:null,lat:69.63186,lng:18.9221,zone:3,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:180,est_sain:"Oui",date_construction:"29/03/1994",commentaire:null,lat:38.731148,lng:-9.3641114,zone:2,h1:"Non",h2:"Oui",h3:"Oui",h4:"Oui",h5:"Non",h6:"Non",h7:"Oui",h8:"Non"},
{id:181,est_sain:"Non",date_construction:"01/09/1994",commentaire:null,lat:11.992373,lng:119.853171,zone:2,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Non",h8:"Non"},
{id:182,est_sain:"Oui",date_construction:"17/09/2013",commentaire:null,lat:50.496272,lng:32.7188892,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:183,est_sain:"Oui",date_construction:"24/04/2006",commentaire:null,lat:-15.24014,lng:-69.527634,zone:8,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:184,est_sain:"Non",date_construction:"13/08/2016",commentaire:null,lat:52.1124967,lng:26.0634602,zone:7,h1:"Oui",h2:"Non",h3:"Oui",h4:"Non",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:185,est_sain:"Non",date_construction:"04/09/2007",commentaire:null,lat:26.337937,lng:115.36319,zone:3,h1:"Non",h2:"Oui",h3:"Oui",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:186,est_sain:"Non",date_construction:"23/05/2015",commentaire:null,lat:43.047003,lng:26.3391256,zone:8,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:187,est_sain:"Oui",date_construction:"05/07/2013",commentaire:null,lat:51.9038827,lng:23.1771438,zone:2,h1:"Oui",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:188,est_sain:"Oui",date_construction:"04/04/2004",commentaire:null,lat:44.202131,lng:38.8907294,zone:7,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Non"},
{id:189,est_sain:"Non",date_construction:"30/08/1999",commentaire:null,lat:-8.197451,lng:112.6079459,zone:8,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:190,est_sain:"Oui",date_construction:"29/05/2015",commentaire:"Serveur du SRC (Service de renseignement de la Confédération / Suisse).",lat:-8.4837797,lng:115.279613,zone:8,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Oui",h7:"Oui",h8:"Oui"},
{id:191,est_sain:"Non",date_construction:"01/07/2019",commentaire:null,lat:-31.4311903,lng:-62.0729285,zone:3,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Non",h7:"Non",h8:"Non"},
{id:192,est_sain:"Oui",date_construction:"29/06/1997",commentaire:null,lat:50.1381207,lng:30.7373521,zone:7,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:193,est_sain:"Oui",date_construction:"05/02/1996",commentaire:null,lat:38.7759506,lng:-9.2661131,zone:4,h1:"Oui",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"},
{id:194,est_sain:"Non",date_construction:"07/06/2003",commentaire:null,lat:35.0298547,lng:71.3542319,zone:4,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Non",h7:"Oui",h8:"Oui"},
{id:195,est_sain:"Non",date_construction:"19/04/2009",commentaire:null,lat:11.2210043,lng:13.4876688,zone:7,h1:"Non",h2:"Oui",h3:"Non",h4:"Non",h5:"Non",h6:"Non",h7:"Non",h8:"Oui"},
{id:196,est_sain:"Oui",date_construction:"15/12/2001",commentaire:null,lat:54.6711201,lng:18.3894523,zone:8,h1:"Non",h2:"Oui",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:197,est_sain:"Non",date_construction:"08/07/2019",commentaire:null,lat:48.5412136,lng:22.5089067,zone:6,h1:"Non",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Oui",h8:"Non"},
{id:198,est_sain:"Oui",date_construction:"22/02/2000",commentaire:null,lat:-26.8039789,lng:-48.6489134,zone:5,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Oui"},
{id:199,est_sain:"Non",date_construction:"17/02/2020",commentaire:null,lat:57.9870012,lng:33.9071546,zone:4,h1:"Oui",h2:"Non",h3:"Non",h4:"Oui",h5:"Non",h6:"Oui",h7:"Oui",h8:"Non"},
{id:200,est_sain:"Oui",date_construction:"07/07/2020",commentaire:null,lat:14.6014622,lng:-91.6087417,zone:6,h1:"Non",h2:"Non",h3:"Oui",h4:"Oui",h5:"Oui",h6:"Oui",h7:"Non",h8:"Non"}]
