//Importation des bibliotheques
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';

//liste des images (des notices)
const itemData = [
  {
    img: '/img/hardware_img/girlduino1.jpg',
    title: 'guirlduino1',
  },
  {
    img: '/img/hardware_img/girlduino2.jpg',
    title: 'girlduino2',
  },
  {
    img: '/img/hardware_img/girlduino3.jpg',
    title: 'girlduino3',
  },
  {
    img: '/img/hardware_img/girlduino4.jpg',
    title: 'girlduino4',
  },
  {
    img: '/img/hardware_img/girlduino5.jpg',
    title: 'girlduino5',
  }
];

function PaperComponent(props) {
  return (
    //rend le modal déplaçable sur la page
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{ margin: '50px' }} />
    </Draggable>
  );
}

//Fonction de rendu du composant
export default function Schemas(props) {

  const handleClose = () => {
    props.setOpen(false);
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      maxWidth="1000px"
    >
      <DialogTitle style={{ cursor: 'move', textAlign: 'center' }} id="notice">
        Notice de Branchement
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        {itemData.map((item, index) => ( // Pour chaque element de l'obj itemData faire une image
          <Box key={index}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              width="80%"
            />
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}

