//Importation des bibliotheques
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import EnigmeContext from '../EContext';//Importation du contexte pour gérer les boutons Suivant et Précédent

import { caracteresCibles } from '../../data/codeprogradata';
import { useMediaQuery } from '@mui/material';
import CodeComponent from './CodeComponent';

//Fonction rendu de la page
export default function Carac() {

  //importation des fonction pour les boutons Suivant et Précédent
  const { setActivatePrev, setActivateNext, setDisableAll } = React.useContext(EnigmeContext)

  React.useEffect(() => {
    setDisableAll();//desactiver de base les boutons Précédent et Suivant
    setActivatePrev();//activation du bouton Précédent de base
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Fonction qui vérifie la saisie de l'utilisateur, si bonne réponse -> validation de l'énigme et bouton suivant activé
  const validerReponse = () => {
    let text = document.getElementById("reponse-textField").value
    if (caracteresCibles.findIndex(element => element === text) !== -1) {
      setActivateNext();//activation du bouton Suivant de base
      handleClickValidation();
    } else {
      handleClickError();//"popup" alerte qui indique une mauvaise saisie de l'utilisateur
    }
  }

  //Alerts
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  //Fontions handleClick qui gére les "popup" alerte qui indique la mauvaise ou bonne saisie de l'utilisateur
  const handleClickValidation = () => {
    setOpen(true);
    setOpen2(false);
  };

  const handleClickError = () => {
    setOpen(false);
    setOpen2(true);
  };

  //Fontions handleClose qui ferme les "popup" alerte
  const handleCloseValidation = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen2(false);
  };

  const isTabletBreakPoint = useMediaQuery('(max-width:1024px)'); 

  return (
    <>
      <CodeComponent /> {/*Composant CodeComponent qui affiche le code à deviner*/}
      <Box sx={{ display: 'flex', gap: isTabletBreakPoint ? '0' : '1rem' , flexDirection: isTabletBreakPoint ? 'column' : 'row' , justifyContent: 'center', mb: '20px', padding: '0px 20px' }}>
        <TextField //TectField à saisir
          id="reponse-textField"
          label="MonCaractereATester = "
          variant="outlined"
          sx={{ minWidth: isTabletBreakPoint ? '90%' : '500px',  }}
          type="text"
          autoComplete="off"
        /><br></br>
        <Button sx={{ backgroundColor: 'gray', marginLeft: '0px' }}
          variant="contained"
          color="primary"
          onClick={validerReponse} //Au click vérifier la réponse de l'utilisateur
        >
          Tester le caractère
        </Button>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseValidation}>
        <Alert onClose={handleCloseValidation} severity="success" sx={{ width: '100%' }}>
          Succès ! Vous avez utilisé le bon caractère. Trouvez maintenant le langage associé à ce caractère.
        </Alert>
      </Snackbar>
      <Snackbar open={open2} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          Échec ! Essayez un autre caractère.
        </Alert>
      </Snackbar>
    </>
  );
} 
