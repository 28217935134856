import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';

export default function FinalDialogBDD(props) {
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">
                {"Envoi des données réussi !"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Pensez à regarder la documentation pour connaître l'heure du lancement du virus !
                    Rappel : Prenez note de l’heure à laquelle tous les serveurs sont allumés. H2 n'est pas un créneau horaire.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Link to="/bdd/2" style={{ textDecoration: 'none' }}>
                    <Button onClick={handleClose} autoFocus>
                        Suivant
                    </Button>
                </Link>
                <Button onClick={handleClose}>Fermer le message</Button>
            </DialogActions>
        </Dialog>
    );
}