import * as React from 'react';
import { authData } from '../data/authdata';
import { SHA256 } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import Cookies from 'js-cookie';
import { Badge } from '@mui/material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        Greenternet
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Erreur texte ?
  const [iserror, setIsError] = React.useState(false);

  const navigate = useNavigate();

  //Si mots de passe ok alors on se TP sur la page 0 correspondante ex.(/code/0)
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (SHA256(data.get('password')).toString() === authData[props.index].password) {
      Cookies.set('auth' + authData[props.index].enigme + 'token', SHA256(data.get('password')).toString())
      setIsError(false);
      if (authData[props.index].enigme === "admin") {
        navigate('/admin');
      } else {
        navigate('/' + authData[props.index].enigme + '/0');
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: red[500] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography>
            {"Authentification " + authData[props.index].fullname}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              value={authData[props.index].user}
              label="Utilisateur"
              name="user"
              autoFocus
            />
            <TextField
              error={iserror}
              helperText={iserror ? "Le mot de passe est incorrect" : ""}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mots de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              data-testid="password"
            />
             <Badge 
              badgeContent={1} 
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  fontSize: 10,
                  height: 15,
                  minWidth: 15,
                  padding: '0 4px',
                  marginTop: '15px',
                },
              }}
            >
              <Button sx={{marginTop: '10px'}} onClick={handleOpen}>
                Mot de passe oublié ?
              </Button>
            </Badge>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Se Connecter
            </Button>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Information
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {authData[props.index].indication.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Fermer</Button>
          </DialogActions>
        </Dialog>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}