import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import LaunchPad from './Launchpad';
import NoticeBDD from './NoticeBDD';
import FinalDialogBDD from './FinalDialogBDD';

import EnigmeContext from '../EContext';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { serveurs, columns, g1data, g2data, g3data, colors } from '../../data/bdddata';
import { Chip, useMediaQuery } from '@mui/material';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EnigmeBDD1() {
    const [category, setCategory] = React.useState(0); //catégorie des tabs
    const [page, setPage] = React.useState(0); //page sélectionné dans le dataset
    const [rowsPerPage, setRowsPerPage] = React.useState(10); //pas besoin d'être bilingue...
    const [isInfected, setInfected] = React.useState(false); //Filtre du graphique g3
    const [pressedButtons, setPressedButtons] = React.useState([]);//etat du launchpad
    const [openSnack, setOpenSnack] = React.useState(false); //ouverture ou la fermeture d'un composant de notification.
    const [severity, setSeverity] = React.useState("info"); //stocke une chaîne de caractères indiquant le niveau de gravité d'une notification.
    const [snackMessage, setSnackMessage] = React.useState(""); //stocke une chaîne de caractères représentant le contenu d'une notification.
    const [selectedImg, setSelectedImg] = React.useState(""); //Image des docs
    const [openDoc, setOpenDoc] = React.useState(false); //Ouverture du doc en grand ecran
    const [openFinal, setOpenFinal] = React.useState(false); //Ouverture de la boîte de dialogue de fin
    

    const { setActivatePrev, setActivateNext } = React.useContext(EnigmeContext)

    //Ajout des couleurs avec les données
    const dataWithColorsG1 = g1data.map((item, index) => ({ ...item, fill: colors[index % colors.length] }));
    const dataWithColorsG2 = g2data.map((item, index) => ({ ...item, fill: colors[index % colors.length] }));
    const dataWithColorsG3 = g3data.map((item, index) => ({ ...item, fill: colors[index % colors.length] }));

    React.useEffect(() => {
        setActivatePrev();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Trouve tout les éléments dans le graphique g3 avec une valeur de 100 et fait correspondre les valeurs X,Y avec les boutons du launchpad
    const handleClickSend = () => {
        let finded = 0;
        let toFind = 0;
        g3data.forEach((obj, index) => {
            for (let i = 1; i < (colors.length + 1); i++) {
                const element = obj[`h${i}`];

                if (element === 100) {
                    toFind++;
                    if (pressedButtons.some(button => button.row === i && button.column === index)) {
                        finded++;
                    }
                }

            }
        })
        if (pressedButtons.length < toFind || pressedButtons.length > toFind) {
            setSnackMessage("Impossible de valider la saisie... Réessayer");
            handleOpenSnack();
            setSeverity("error");
        }
        else if (finded === toFind) {
            setSnackMessage("Données envoyés... Succès !");
            setSeverity("success");
            handleOpenSnack();
            setOpenFinal(true);
            setActivateNext();
        } else {
            setSnackMessage("Impossible de valider la saisie... Réessayer");
            setSeverity("error");
            handleOpenSnack();
        }
    }
    const handleClickImages = (src) => {
        setSelectedImg(src);
        setOpenDoc(true);
    }
    /*
     Cette fonction est appelée lorsqu'un utilisateur ferme la boîte de notification
    */
    const handleCloseSnack = () => {
        setOpenSnack(false);
    }
    /*
    Cette fonction est appelée lorsqu'un utilisateur doit afficher une notification. Elle ouvre la boîte de notification
    */
    const handleOpenSnack = () => {
        setOpenSnack(true);
    }

    const handleChangeCategory = (_event, newCategory) => {
        setCategory(newCategory);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const formatYAxis = (tickItem) => {
        return `${tickItem / 10 ** 6}M`;
    };

    const formatYAxisPercent = (tickItem) => {
        return `${tickItem}%`;
    }

    const handleChangeFilter = () => {
        if (isInfected) {
            setInfected(false);
        } else {
            setInfected(true);
        }
    }

    const isTabletBreakpoint = useMediaQuery('(max-width: 1024px)');
    const graphicBreakpoint = useMediaQuery('(max-width: 1239px)');

    return (
        <Box sx={{ width: '100%', maxWidth: '100%' }}>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <NoticeBDD src={selectedImg} setOpen={setOpenDoc} open={openDoc} />
            <FinalDialogBDD setOpen={setOpenFinal} open={openFinal} />
            <Tabs
                
                value={category}
                variant={isTabletBreakpoint ? 'scrollable' : 'fullWidth'}
                scrollButtons
                allowScrollButtonsMobile
                onChange={handleChangeCategory}
                sx={{
                    '& .MuiTabs-indicator': {
                        background: 'linear-gradient(45deg, #6B22E4 40%, #2ECD75 60%)',
                    },
                    '& .MuiTab-root': {
                        color: '#7200fe',
                    },
                    '& .Mui-selected': {
                        color: '#7200fe',
                    },
                    margin: 'auto',
                }}
            >
                <Tab label="Launchpad" sx={{ borderRight: '1px solid #ccc' }}/>
                <Tab label="Tableau de données" />
                <Tab label="Serveurs infectés/zones" />
                <Tab label="Serveurs allumés/zones en fonction de l'heure" />
            </Tabs>
            <TabPanel value={category} index={0}>
                <div className='wrapperBdd'>
                    <div className="left">
                        <Grid item>
                            <LaunchPad pressedButtons={pressedButtons} setPressedButtons={setPressedButtons} />
                        </Grid>
                    </div>
                    {isTabletBreakpoint && (
                        <div className="buttonWrapper">
                            <Button sx={{marginTop: '10px' ,width: '200px'}} onClick={handleClickSend} variant='contained' color='success'>
                                    Valider la sélection
                            </Button>
                        </div>
                    )}
                    <div className="right">
                        <div className="infoWrapper">
                            <div className='imgWrapper'>
                                <img alt="notice1" style={{cursor: "pointer"}} src='/img/bdd_img/docBDD1.jpg' width={"100%"} onClick={() => handleClickImages('/img/bdd_img/docBDD1.jpg')} />
                            </div>
                            <div className='imgWrapper'>
                                <img alt="notice2" style={{cursor: "pointer"}} src='/img/bdd_img/docBDD2.jpg' width={"100%"} onClick={() => handleClickImages('/img/bdd_img/docBDD2.jpg')} />
                            
                            </div>
                            <div className='imgWrapper'>
                                <img alt="notice3" style={{cursor: "pointer"}} src='/img/bdd_img/docBDD3.jpg' width={"100%"} onClick={() => handleClickImages('/img/bdd_img/docBDD3.jpg')} />
                            </div>
                        </div>
                        <div className="buttonWrapper">
                            <Button sx={{marginTop: 5, width: '30%'}} onClick={handleClickSend} variant='contained' color='success'>
                                    Valider la sélection
                            </Button>
                        </div>
                    </div>
                    {isTabletBreakpoint && (
                        <div className='chipWrapper'>
                            <Chip sx={{backgroundColor: '#6B22E4', color: 'white'}} label="Notice 1" onClick={() => handleClickImages('/img/bdd_img/docBDD1.jpg')} />
                            <Chip sx={{backgroundColor: '#6B22E4', color: 'white'}} label="Notice 2" onClick={() => handleClickImages('/img/bdd_img/docBDD2.jpg')} />
                            <Chip sx={{backgroundColor: '#6B22E4', color: 'white'}} label="Notice 3" onClick={() => handleClickImages('/img/bdd_img/docBDD3.jpg')} />
                        </div>
                    )}
                </div>
            </TabPanel>
            <TabPanel value={category} index={1}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {serveurs
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={serveurs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </TabPanel>
            <TabPanel className="serverGraph"  value={category} index={2}>
                <ResponsiveContainer width={graphicBreakpoint ? 1200 : "100%"} height={500}>
                    <BarChart
                        data={dataWithColorsG1}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="zone" />
                        <YAxis tickFormatter={formatYAxis} scale="log" domain={[500000, 'auto']} />
                        <Tooltip />
                        <Bar dataKey="quantity" />
                    </BarChart>
                </ResponsiveContainer>
            </TabPanel>
            <TabPanel className="serverGraph" value={category} index={3}>
                <FormControlLabel
                    control={<Switch
                        checked={isInfected}
                        onChange={handleChangeFilter}
                    />}
                    label="Filtrer seulement les serveurs infectés ?"
                />
                <ResponsiveContainer className="serverGraphChild" width={graphicBreakpoint ? 1200 : "100%"} height={450}>
                    <BarChart
                        data={isInfected ? dataWithColorsG3 : dataWithColorsG2}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="zone" />
                        <YAxis tickFormatter={formatYAxisPercent} />
                        <Tooltip />
                        <Bar dataKey="h1" />
                        <Bar dataKey="h2" />
                        <Bar dataKey="h3" />
                        <Bar dataKey="h4" />
                        <Bar dataKey="h5" />
                        <Bar dataKey="h6" />
                        <Bar dataKey="h7" />
                        <Bar dataKey="h8" />
                    </BarChart>
                </ResponsiveContainer>
            </TabPanel>
        </Box>
    );
}