import React, { useState } from "react";
import { Box, Typography, Paper, Grid, useMediaQuery, Button } from "@mui/material";

import { cadres } from "../../data/codeprogradata";
import EnigmeContext from "../EContext";
import { Link } from "react-router-dom";

export default function EnigmeCodeProgra3() {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  // eslint-disable-next-line no-unused-vars
  const [showHole, setShowHole] = useState(true); // La lampe reste allumée tout le temps

  const isTabletBreakpoint = useMediaQuery("(max-width: 1024px)");
  console.log(isTabletBreakpoint);

  const { setActivatePrev, setDisableAll } = React.useContext(EnigmeContext);
  React.useEffect(() => {
    setDisableAll(); // désactiver de base les boutons précédents et suivants
    setActivatePrev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Enregistre la position de la lampe quand la souris bouge ou lors d'un toucher
  const handleEnter = () => {
    setShowHole(true);
  };

  const handleMove = (event) => {
    const { clientX, clientY } = event.touches ? event.touches[0] : event;
    setPosition({ x: clientX, y: clientY });
  };
  const handleLeave = () => {
    if (!isTabletBreakpoint) {
      setShowHole(false);
    }
  };
  return (
    <>
      <Grid container spacing={1}>
        {cadres.map((cadre, index) => (
          <Grid item key={index} xs={6} md={3} lg={3}>
            <Paper
              style={{
                border: "20px solid #222",
                width: "fit-content", // Ajuste automatiquement la largeur du cadre en fonction de l'image
                boxShadow: "0px 3px 10px 4px rgba(0, 0, 0, .3)",
                display: "flex", // Utilise flexbox pour centrer l'image
                alignItems: "center", // Centre verticalement
                justifyContent: "center", // Centre horizontalement
                maxWidth: cadre.w,
              }}
            >
              <Box
                style={{
                  background: "white",
                  boxShadow: "inset 3px 4px 2px 4px rgba(0, 0, 0, .2)",
                }}
              >
                <Box
                  component="img"
                  alt="MDR"
                  src={cadre.url}
                  style={{
                    display: "block",
                    maxWidth: "80%",
                    margin: "30px auto", // Centre l'image horizontalement
                    marginTop: "10%", // Ajoute une marge en haut
                    marginBottom: "10%", // Ajoute une marge en bas
                  }}
                />
                <Typography align="center">
                  {cadre.text.split("").map((lettre, index) =>
                    lettre === cadre.target ? (
                      <span
                        key={index}
                        style={{
                          fontWeight: "bold",
                          textShadow: `0 0 5px lime, 0 0 10px lime, 0 0 15px lime`,
                        }}
                      >
                        {lettre}
                      </span>
                    ) : (
                      <span key={index}>{lettre}</span>
                    )
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          cursor: "none",
        }}
        onMouseEnter={handleEnter}
        onTouchStart={handleEnter}
        onMouseMove={handleMove}
        onTouchMove={handleMove}
        onMouseLeave={handleLeave}
        onTouchEnd={handleLeave}
      >
        <div>
          <Box
            sx={{
              position: "fixed",
              top: position.y,
              left: position.x,
              transform: "translate(-50%, -50%)",
              width: showHole ? 200 : 0,
              height: showHole ? 200 : 0,
              borderRadius: "50%",
              backgroundColor: "rgba(106,90,205, 0.4)",
              boxShadow: "0 0 0 9999px rgba(0, 0, 0, 1)", // Ajoute un masque
              transition: "width 0.5s, height 0.5s",
            }}
          />
          <Box
            sx={{
              position: "fixed",
              top: position.y,
              left: position.x,
              transform: "translate(-50%, -50%)",
              width: showHole ? 200 : 0,
              height: showHole ? 200 : 0,
              borderRadius: "50%",
              transition: "width 0.5s, height 0.5s",
              backgroundImage:
                "radial-gradient(circle at center, rgba(138,43,226, 0.4), transparent 90%)",
            }}
          />
        </div>
      </div>
      <Link to="/hub" style={{ textDecoration: 'none', position: 'absolute', top: '80px', left: '10px' ,  zIndex: '99999999' }}>
        <Button variant="contained" color="primary" sx={{ background: 'linear-gradient(45deg, #6B22E4, #AB9230)'}}>Retour au hub</Button>
        </Link>
    </>
  );
}
