export const settingdata = {
    timeGame : 45 * 60, //Temps initial en seconds
    hintCreditUse: 0, //Crédit d'indice initial
    hintCreditToGiveAtTime : {//Nombre de crédit qui doivent avoir été donnée à ce temps la (number of credits that must have already been given at that time)
        2700 : 0, //suprimer cette ligne
        2690 : 1, //suprimer cette ligne : 45min - 10sec 
        2680 : 2, //suprimer cette ligne
        2670 : 3, //suprimer cette ligne
        2660 : 3, //suprimer cette ligne
        2650 : 4, //suprimer cette ligne
        2640 : 5, //suprimer cette ligne
        2630 : 5, //suprimer cette ligne
        /*
        2700 : 0, //45* 60 seconds
        2100 : 1, //35* 60 seconds
        1800 : 1, //30* 60 seconds
        1500 : 1, //25* 60 seconds
        1200 : 3, //20* 60 seconds
        900 : 3, //15* 60 seconds
        600 : 5, //10* 60 seconds
        300 : 8, //5 * 60 seconds*/
    },
}

export const expectedCode = ['4C', '2340', '3AM', '!', 'PYTHON'];