import React from 'react';
import Typography from '@mui/material/Typography';
import RequiredMentions from './RequiredMentions';
//var LegalNotice_HTML_Web = require('./mentions_legales_web.htm');

const LegalNotice = () => {
    return (
        <>
            <Typography variant='body2' m='auto' textAlign={'center'}>
            Projet TER réalisé dans le cadre du stage du Master1 MIASHS Informatique et cognition à l’Université de Grenoble Alpes par L.ROUBY, R.JANSON, R.POMMATAU. 
            </Typography>
            <Typography variant='body2' m='auto' textAlign={'center'}>
            Projet développé par la société Logia.
            </Typography>
            {/*<iframe title='Legal Notice' src={LegalNotice_HTML_Web} style={{height: "90vh", width: "100%"}}></iframe>*/}
            <RequiredMentions />
        </>
    );
}

export default LegalNotice;

