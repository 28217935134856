import * as React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import EnigmeContext from './EContext';

import { challenges } from '../data/challenges';
import Cookies from 'js-cookie';


export default function Enigme(props) {
  // Enregistre l'état des boutons suivant et précédent
  const [button, setButton] = React.useState({
    prev: true,
    next: true,
  });

  const location = useLocation();
  const isBdd2 = location.pathname === '/bdd/2';

  const { stepid } = useParams();
  const navigate = useNavigate();

  const setDisablePrev = () => {
    setButton(prevState => ({
      ...prevState,
      prev: true
    }));
  };

  const setActivatePrev = () => {
    setButton(prevState => ({
      ...prevState,
      prev: false
    }));
  };

  const setDisableNext = () => {
    setButton(prevState => ({
      ...prevState,
      next: true
    }));
  };

  const setActivateNext = () => {
    setButton(prevState => ({
      ...prevState,
      next: false
    }));
  };

  const setDisableAll = () => {
    setButton(prevState => ({
      ...prevState,
      next: true,
      prev: true
    }));
  };

  //Quand le bouton suivant est cliqué on change le cookie de sauvegarde à +1 et on se TP à l'énigme suivante
  const HandleClickForward = () => {
    let step = stepid;
    if (step < challenges[props.index].enigme.length + 1) {
      setDisableNext();
      step++;
      Cookies.set(challenges[props.index].enigme + '_state', step, { path: '/' });
      navigate('/' + challenges[props.index].enigme + '/' + step);
    }
  }

  //Quand le bouton précedent est cliqué on change le cookie de sauvegarde à -1 et on se TP à l'énigme précedente
  const HandleClickBefore = () => {
    let step = stepid;
    if (step > 0) {
      setDisablePrev();
      step--;
      Cookies.set(challenges[props.index].enigme + '_state', step, { path: '/' });
      navigate('/' + challenges[props.index].enigme + '/' + step);
    }
  }

  //Choisis l'énigme en fonction du contexte
  const ChooseEnigma = () => {
    return challenges[props.index].steps[stepid];
  }

  return (
    <EnigmeContext.Provider value={{ setActivateNext, setActivatePrev, setDisablePrev, setDisableAll }}>
      {ChooseEnigma()}
      <Stack direction="row" spacing={2} sx={{ margin: 'auto', justifyContent: 'center', marginBottom: '2%' }}>
        {button.prev ? null : <Button data-testid='btnPrev' variant='contained' disabled={button.prev} onClick={() => HandleClickBefore()} sx={{
          background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
        }}>
          Précédent
        </Button>}
        {!isBdd2 && !button.next && (
          <Button 
            data-testid='btnNext' 
            variant='contained' 
            disabled={button.next} 
            onClick={HandleClickForward}
            sx={{
              background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
            }}
          >
            Suivant
          </Button>
        )}
      </Stack>
    </EnigmeContext.Provider>
  )
}