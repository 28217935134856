//Importation des bibliotheques
import React from 'react';
import PlateformCablage from './PlateformCablage';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import EnigmeContext from '../EContext';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Schemas from './Schemas';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

//Theme pour les couleurs
const theme = createTheme({
  palette: {
    primary: {
      main: '#0F7391',//Couleur du bouton Notice et Brancher
    },
    neutral: {
      main: '#7b8285',//Couleur du bouton Reset
    },
  },
});

function EnigmeHardware1() {

  const navigate = useNavigate();

  const [open2, setOpen2] = React.useState(false);

  //Fonction qui ouvre et ferme les alertes
  const handleClickOpen2 = () => {
    setOpen2(true);
  }

  const handleCloseError = () => {
    setOpen2(false);
  }

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true);
  }

  const [bonCablage, setBonCablage] = React.useState(false);

  //Si le cablage est bon, pasage à l'étape suivante
  const handleReset = () => {
    if (bonCablage === true) {
      Cookies.set("hardware_state", 2, { path: '/' });
      navigate("/hardware/2");
    }
    else {
      handleClickOpen2();
    }
  };

  const { setDisableAll } = React.useContext(EnigmeContext);
  React.useEffect(() => {
    setDisableAll();//Desactiver par défaut les boutons Suivant et Précédents
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //trace le cable sur l'image
  //prend en paramètre les coordonnées des 2 points et la couleur du trait
  function handleCablage(x1, y1, x2, y2, color) {
    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    //la couleur du cable dépend de la couleur de la borne du robot concernée
    switch (color) {
      case "rouge":
        ctx.strokeStyle = '#FF0000';
        break;
      case "jaune":
        ctx.strokeStyle = '#FFCC00';
        break;
      case "bleu":
        ctx.strokeStyle = '#000BFF';
        break;
      case "vert":
        ctx.strokeStyle = '#06AE00';
        break;
      default:
        ctx.strokeStyle = 'black';
        break;
    }
    ctx.lineWidth = 6;
    ctx.stroke();
  }

  //Efface tous les cables
  function handleDecablage() {
    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }


  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} justifyContent={'center'} textAlign="center">
            <Box marginTop={'50px'} px={'20px'} marginLeft={'15px'} display={'flex'} flexWrap={'wrap'} gap={'1rem'} justifyContent={'center'}>
              <Button sx={{ color: "white"}} color="neutral" variant='contained' onClick={handleReset} startIcon={<PlayArrowIcon />}>RESET</Button>
              <Button sx={{ color: "white"}} variant='contained' onClick={handleClickOpen}>Notice de branchement</Button>
            </Box>
          <Grid className='hideScroll'  item xs={12} marginTop={'-20px'} overflow={'auto'} textAlign="center">
            <Box sx={{ width: '1000px', height: '480px', backgroundImage: 'url(/img/hardware_img/girlduino.png)', m: "auto", transform: 'scale(0.7)', marginTop: '-40px' }}>
              <canvas id="canvas" width={1000} height={480} />
            </Box>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <PlateformCablage handleCablage={handleCablage} handleDecablage={handleDecablage} bonCablage={bonCablage} setBonCablage={setBonCablage} />
          </Grid>
        </Grid>
        <Schemas open={open} setOpen={setOpen} />
        <Snackbar open={open2} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            Pas de réponse du robot. Mauvais branchement.
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </>
  );
}

export default EnigmeHardware1;