import { Box, TextField } from '@mui/material';
import * as React from 'react';
import { Typography } from '@mui/material';
import { reponseTerminal2 } from '../../data/networkdata';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function TerminalDialog(props) {
  const navigate = useNavigate();
  const [index, setIndex] = React.useState(0);

  //Gère les commandes tapés
  let res = (e) => {
    console.log(e.keyCode);
    let answer = e.target.value.toUpperCase();;
    if (e.keyCode === 13) {
      //Quand le chemin est le bon ainsi que la commande tapé, l'étape suivante démarre
      if ((props.goodPath === "S3S4ALL-1" && answer === "PING 34.76.60.69")) {
        Cookies.set("network_state", 2, { path: '/' });
        navigate("/network/2");
        return;
      }
      //Cherche si la commande est reconnu dans le tableau reponseTerminal2
      for (let i in reponseTerminal2) {
        if (answer === reponseTerminal2[i].commande) {
          //La commande est reconnue, mise à jour d'index et envoie réponse stockée dans reponseTerminal2
          setIndex(i);
          document.getElementById("erreur").style.display = "none";
          answer = "";
          document.getElementById("reponse").value = "";
          document.getElementById("terminal").style.display = "none"
          document.getElementById("reponseCommande").style.display = "block";
          //Fait disparaitre et raparaitre les éléments pour simuler un vrai termianl
          setTimeout(function () { document.getElementById("terminal").style.display = "block" }, 200);
          return;
        }
      }
      //La réponse n'est pas reconnu, renvoie le mesage d'erreur
      document.getElementById("reponseEtErreur").style.display = "none";
      //Fait disparaitre et raparaitre les éléments pour simuler un vrai termianl
      setTimeout(function () { document.getElementById("reponseEtErreur").style.display = "block" }, 200);
      document.getElementById("erreur").style.display = "block";
      document.getElementById("reponse").value = "";
      answer = "";
    }
  }

  return (
    <>
      <Box sx={{ backgroundColor: 'black', width: '100%', height: '200px', overflow: 'auto' }}>
        <Typography paddingLeft='15px' paddingTop={'15px'} color="white">Terminal  - Connecté en tant que @Greenternet-console</Typography>
        <Box margin="10px" id='terminal'>
          <Typography id="reponseCommande" color="white" display="none">{"> "}{reponseTerminal2[index].reponse}</Typography>
          <Box id="reponseEtErreur">
            <Typography id="erreur" color="white" display="none" marginTop="10px">{"> "} La commande n'est pas reconnue</Typography>
            <Box sx={{ display: "flex" }}>
              <Typography color="white" width='2%' marginTop="30px">{"> "}</Typography>
              <TextField
                id="reponse"
                variant="filled"
                color="black"
                sx={{ margin: '5px', backgroundColor: 'black', input: { color: "white" }, width: '98%' }}
                onKeyDown={res}
                autoComplete='off'>
              </TextField>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}