import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Button, Box } from '@mui/material';
import RequiredMentions from './RequiredMentions';

const images = [
  {
    index: 0,
    url: 'cards/Dora.png',
    title: '',//Admin Réseau
    width: '210px'
  },
  {
    index: 1,
    url: 'cards/Marguerite.png',
    title: '',//Communication Digitale
    width: '210px'
  },
  {
    index: 2,
    url: 'cards/Marie.png',
    title: '',//Big Data & Base de données
    width: '210px'
  },
  {
    index: 3,
    url: 'cards/Leatitia.png',
    title: '',//Hardware & Robotique
    width: '210px'
  },
  {
    index: 4,
    url: 'cards/Marjolaine.png',
    title: '',//Code & Programmation
    width: '210px'
  },

];

//CSS du bouton
const ImageButton = styled(ButtonBase)(({ theme }) => ({
  margin: 'auto',
  marginBottom: theme.spacing(2),
  position: 'relative',
  minWidth: '180px',
  height: 350,
  [theme.breakpoints.down('sm')]: {
    minWidth: '290px!important', // Overrides inline-style
    height: 480,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0,
    },
  },
  borderRadius: '10px'
}));

//CSS de l'image contenu dans le bouton
const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  borderRadius: '10px'
});




//CSS de l'image 2 ??
const Image = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

//CSS de la box noire transparente au dessus de l'image
const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'white',
  opacity: 0.2,
  transition: theme.transitions.create('opacity'),
  borderRadius: '10px',
  overflow: 'hidden',
}));



export default function Hub(props) {
  const navigate = useNavigate();

  // Quand on clique sur le bouton on se TP au bon endroit en fonction de ce qui est contenu dans le cookie
  const handleButtonClickImg = (i) => {
    switch (i) {
      case 0:
        navigate("/network/" + props.stepid[0]);
        break;
      case 1:
        navigate("/social/" + props.stepid[1]);
        break;
      case 2:
        navigate("/bdd/" + props.stepid[2]);
        break;
      case 3:
        navigate("/hardware/" + props.stepid[3]);
        break;
      case 4:
        navigate("/code/" + props.stepid[4]);
        break;
      default:
        break;
    }
  }

  return (
    <Box 
    display="flex" alignItems="center" 
    justifyContent="space-between"
    flexDirection="column"
    marginTop='20px'
    minHeight='calc(100vh - 120px)' sx={{p:'5px'}}>
      <div>
        <Grid2 style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
          {images.map((image) => (
            <ImageButton
              onClick={
                () => handleButtonClickImg(image.index)
              }
              focusRipple
              key={image.index}
              style={{
                width: image.width,
                margin: '15px',
                position: 'relative',
              }}
            >
              <ImageSrc data-testid={"url:"+image.url} style={{ backgroundImage: `url(${image.url})`}} />
              <ImageBackdrop className="MuiImageBackdrop-root"/>
              <Image>
                <Typography variant='h5'>
                  {image.title}
                </Typography>
              </Image>
            </ImageButton>
          ))}
        </Grid2>
        <Button variant='contained' size='large' onClick={() => { navigate('/final') }} sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          marginTop: '40px',
          marginBottom: '20px',
          fontSize: '30px',
          background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
        }}>
          Énigme Finale
        </Button>
      </div>
      <div className='hubRequiredMentionsWrapper'>
        <RequiredMentions />
      </div>
    </Box>
  );

}