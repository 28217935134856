import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import MessageIcon from '@mui/icons-material/Message';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import Message from './Message';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import Tweet from './Tweet';
import CreateTweet from './CreateTweet';
import KnootAdmin from './KnootAdmin';
import KnootContext from './KContext';

import { socialData, target, steps } from '../../data/socialdata';
import Cookies from 'js-cookie';

import EnigmeContext from '../EContext';
import { useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Height } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function EnigmeSocial1() {
  const [completed, setCompleted] = React.useState({}); //Il est utilisé pour suivre les étapes terminées dans la progression de l'utilisateur.
  const [isStepActive, setIsStepActive] = React.useState(false); //état booléen qui indique si l'utilisateur a activé les étapes de progression.
  const [openMessage, setOpenMessage] = React.useState(false); //ouverture boite de dialogue des messages.
  const [openConnexion, setOpen2Connexion] = React.useState(false); //ouverture boite de dialogue de connexion.
  const [openCreate, setOpenCreate] = React.useState(false);//ouverture ou la fermeture d'un composant de création de tweet.
  const [openAdmin, setOpenAdmin] = React.useState(false); //ouverture ou la fermeture du composant de l'administration.
  const [openSnack, setOpenSnack] = React.useState(false); //ouverture ou la fermeture d'un composant de notification.
  const [severity, setSeverity] = React.useState("info"); //stocke une chaîne de caractères indiquant le niveau de gravité d'une notification.
  const [snackMessage, setSnackMessage] = React.useState(""); //stocke une chaîne de caractères représentant le contenu d'une notification.
  const [password, setPassword] = React.useState(""); //stocke une chaîne de caractères représentant le mot de passe entré par l'utilisateur.
  const [disableAddTweet, setDisableAddTweet] = React.useState(true); //indique si l'ajout de tweet est désactivé ou non.
  const [contentNewTweet, setContentNewTweet] = React.useState(""); //stocke une chaîne de caractères représentant le contenu d'un nouveau tweet.
  const [urlNewTweet, setUrlNewTweet] = React.useState(""); //stocke une chaîne de caractères représentant l'URL d'une image associée à un nouveau tweet.
  const [showNewTweet, setShowNewTweet] = React.useState(false); //indique si un nouveau tweet doit être affiché ou non.
  const [isLoggedIn, setIsLoggedIn] = React.useState(false); //indique si l'utilisateur est connecté ou non.
  const [notif, setNotif] = React.useState(false); //indique si une notification doit être affichée ou non.
  const [numberMessage, changeMessage] = React.useState(0); //stocke un nombre représentant le nombre de messages
  const [iserror, setIsError] = React.useState(false); //indique s'il y a une erreur ou non
  const [goal, setGoal] = React.useState({}); //Il est utilisé pour représenter l'état des objectifs.
  const [isFinalStep, setIsFinalStep] = React.useState(false); //indique si l'utilisateur a atteint la dernière étape.

  //Enregistre la progression des objectifs
  React.useEffect(() => {
    const get_goal = JSON.parse(localStorage.getItem('goal'));
    if (get_goal) {
      setNotif(true);
      setGoal(get_goal);
    } else {
      setNotif(true);
      const newGoal = {};
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i].label;
        newGoal[step] = [];
      }
      localStorage.setItem('goal', JSON.stringify(newGoal));
      setGoal(newGoal);
    }
    const show_step = localStorage.getItem('show_step');
    if (show_step) {
      setIsStepActive(show_step);
    }
    const completed_steps = JSON.parse(localStorage.getItem('completed'));
    if (completed_steps) {
      setCompleted(completed_steps);
    }
    const isnotif = JSON.parse(localStorage.getItem('notif'));
    if (isnotif) {
      setNotif(isnotif);
    }
    const nbrmessage = JSON.parse(localStorage.getItem('numberMessage'));
    if (nbrmessage) {
      changeMessage(nbrmessage);
      if (nbrmessage === 1) {
        setDisableAddTweet(false);
      }
    }
    const newtweet = JSON.parse(localStorage.getItem('newtweet'));
    if (newtweet) {
      setUrlNewTweet(newtweet.url);
      setContentNewTweet(newtweet.content);
      setShowNewTweet(true);
    }
  }, []);

  const { setDisableAll} = React.useContext(EnigmeContext);
  React.useEffect(() => {
    setDisableAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  Cette fonction est appelée lorsqu'un utilisateur clique sur le bouton "message".
  Elle ouvre une boîte de dialogue pour afficher les messages.
  */
  const handleClickOpen = () => {
    if (numberMessage === 0) {
      Cookies.set("social_state", 1);
    } else if (numberMessage === 2) {
      Cookies.set("social_state", 4);
       setIsFinalStep(true);
    }
    setOpenMessage(true);
    localStorage.setItem('notif', false);
    setNotif(false);
    localStorage.setItem('show_step', true);
    setIsStepActive(true);
  };

  /*
  Cette fonction est appelée lorsqu'un utilisateur clique sur le bouton "Admin".
  Si l'utilisateur est connecté, elle ouvre la page d'administration.
  Sinon, elle ouvre une boîte de dialogue pour demander les identifiants de connexion
  */
  const handleClickOpen2 = () => {
    if (isLoggedIn) {
      setOpenAdmin(true);
    } else {
      setOpen2Connexion(true);
    }
  };
  /*
  Cette fonction est appelée lorsqu'un utilisateur ferme la boîte de dialogue de connexion administrateur
  */
  const handleClose2 = () => {
    setOpen2Connexion(false);
  };

  /*
  Cette fonction est appelée lorsqu'un utilisateur clique sur le bouton pour créer un nouveau tweet.
  Elle ouvre une boîte de dialogue pour permettre à l'utilisateur de créer un tweet
  */
  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  /*
   Cette fonction est appelée lorsqu'un utilisateur ferme la boîte de notification
  */
  const handleCloseSnack = () => {
    setOpenSnack(false);
  }
  /*
  Cette fonction est appelée lorsqu'un utilisateur doit afficher une notification. Elle ouvre la boîte de notification
  */
  const handleOpenSnack = () => {
    setOpenSnack(true);
  }

  /*
  Cette fonction vérifie si un tweet est interdit en fonction des objectifs définis.
  Si un tweet correspond aux critères de bannissement, il est modifié pour afficher un contenu interdit.
  */
  const isBanned = (tweet) => {
    let tweet_post = tweet;
    if (goal.ban) {
      if (goal.ban.includes(tweet.pseudo)) {
        tweet_post.pp = "banned.png";
        tweet_post.pseudo = "[Utilisateur Banni]";
      }
      if (goal.ban.some(substring => tweet.content.includes(substring))) {
        tweet_post.banned = true;
      }
    }
    return <Tweet
      pp={tweet_post.pp}
      pseudo={tweet_post.pseudo}
      image={tweet_post.image}
      content={tweet_post.content}
      likes={tweet_post.likes}
      iscontentBanned={tweet_post.banned}
      date={tweet_post.date} />;
  }

  /*
  Cette fonction fait progresser le nombre de messages. Selon le nombre de messages complétés, elle met à jour l'état de la notification,
  active ou désactive le bouton pour ajouter un nouveau tweet, et met à jour l'état de l'étape active
  */
  const advanceMessage = () => {
    switch (Object.keys(completed).length) {
      case 1:
        Cookies.set("social_state", 2);
        changeMessage((nmes) => nmes + 1);
        localStorage.setItem('numberMessage', (numberMessage + 1));
        setNotif(true);
        localStorage.setItem('notif', true);
        setDisableAddTweet(false);
        break;
      case 2:
        Cookies.set("social_state", 3);
        changeMessage((nmes) => nmes + 1);
        localStorage.setItem('numberMessage', (numberMessage + 1));
        setNotif(true);
        localStorage.setItem('notif', true);
        setDisableAddTweet(true);
        break;
      default:
        break;
    }
  }

  /*
  Cette fonction est appelée lorsqu'un utilisateur passe à l'étape suivante.
  Elle met à jour l'état des objectifs complétés et appelle la fonction advanceMessage pour mettre à jour l'état des messages
  */
  const handleNext = (type) => {
    if (goal[type].length === (target[type].length - 1)) {
      const newCompleted = completed;
      newCompleted[type] = true;
      localStorage.setItem("completed", JSON.stringify(newCompleted));
      setCompleted(newCompleted);
    }
    advanceMessage();
  };

  /*
  Cette fonction vérifie si un pseudo est banni ou non. Si le pseudo est banni, un message de succès est affiché.
  Sinon, un message d'erreur est affiché.
  */
  const checkBan = (item) => {
    if (!isLoggedIn) {
      setSeverity("warning");
      setSnackMessage("Vous n'êtes pas connecté·e en tant qu'administrateur·euse !!");
      handleOpenSnack();
      return;
    }
    if (goal.ban.includes(item)) {
      setSeverity("info");
      setSnackMessage(`${item} a déjà été banni·e`);
      handleOpenSnack();
    } else if (target.ban.includes(item) && isLoggedIn) {
      setSeverity("success");
      setSnackMessage(`${item} a été banni·e, bravo !`);
      let bannedList = JSON.parse(localStorage.getItem("goal"));
      bannedList["ban"].push(item);
      setGoal(bannedList);
      localStorage.setItem("goal", JSON.stringify(bannedList));
      handleOpenSnack();
      handleNext("ban");
    } else {
      setSeverity("warning");
      setSnackMessage("Ce compte ne semble pas être commandé par un robot.");
      handleOpenSnack();
    }
  }
  /*
  Cette fonction vérifie si une URL d'upload est autorisée ou non.
  Si l'URL est autorisée, un message de succès est affiché.
  Sinon, un message d'erreur est affiché.
  */
  const checkUpload = (url, content) => {
    if (target.upload.includes(url)) {
      setSeverity("success");
      setSnackMessage(`Le knoot a été upload, félicitation !`);
      let objUpload = JSON.parse(localStorage.getItem("goal"));
      objUpload["upload"].push(url);
      setGoal(objUpload);
      localStorage.setItem("goal", JSON.stringify(objUpload));
      handleOpenSnack();
      handleNext("upload");
      setUrlNewTweet(url);
      setContentNewTweet(content);
      setShowNewTweet(true);
      localStorage.setItem("newtweet", JSON.stringify({ url: url, content: content }));
    } else {
      setSeverity("error");
      setSnackMessage("Le knoot n'a pas pû être uploadé car il n'était pas assez léger !");
      handleOpenSnack();
    }
  }

  /*
  Cette fonction est appelée lorsque l'utilisateur soumet le formulaire de connexion administrateur.
  Elle vérifie si le mot de passe saisi est correct et met à jour l'état de connexion en conséquence.
  */
  const handleSubmit = () => {
    if (password === "knootadmin") {
      setIsLoggedIn(true);
      setOpen2Connexion(false);
      setOpenAdmin(true);
    } else {
      setIsError(true);
    }
  };

  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <KnootContext.Provider value={{ checkBan, checkUpload }}>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={severity} sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <KnootAdmin open={openAdmin} setOpen={setOpenAdmin} />
      <CreateTweet open={openCreate} setOpen={setOpenCreate} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid className='gridParent' container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item order='0' xs={12} sm={12} md={4} sx={{ textAlign: 'center', marginBottom: '-40px' }}>
            <Box sx={{ position: '' }}>
              <Box sx={{ margin: '50px' }}>
                <img src="/img/tweet_img/logo-knooter.png" alt="logoknooter" width="200px"></img>
              </Box>
              <Box sx={{
                display: { xs: 'flex', sm: 'flex', md: 'block', lg: 'block', xl: 'block' },
                justifyContent: { xs: 'center', sm: 'center', md: 'initial', lg: 'initial', xl: 'initial' },
                flexWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap', lg: 'nowrap', xl: 'nowrap' }
              }}>
                <Box sx={{ margin: '10px' }}>
                  <Button variant="outlined" color="primary" sx={{ minHeight: '50px', maxWidth: '150px', minWidth: '150px' }} onClick={handleClickOpen}>
                    <Badge badgeContent={notif ? 1 : 0} color="error" sx={{ marginRight: '15px' }}>
                      <MessageIcon color="action" />
                    </Badge>
                    message
                  </Button>
                </Box>
                <Box sx={{ margin: '10px' }}>
                  <Button variant="outlined" startIcon={<SettingsIcon color='action' />} color="primary" sx={{ minHeight: '50px', maxWidth: '150px', minWidth: '150px' }} onClick={handleClickOpen2}>
                    Admin
                  </Button>
                </Box>
                {isFinalStep && (
                  <Link 
                  to='/hub' 
                  style={{
                    height: '50px',
                    margin: '10px',
                    display: 'inline-block'
                  }}
                >
                  <Button variant="outlined" color="primary" sx={{ minHeight: '50px', maxWidth: '150px', minWidth: '150px' }}>
                    Retour au hub
                  </Button>
                </Link>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item order={isXsOrSm ? '2' : '1'} xs={12} sm={12} md={4}>
            <Grid container spacing={2} marginTop={isXsOrSm ? '0px' : '50px'}>
              <Grid item maxWidth='80%!important' margin='auto' xs={12}>
                {showNewTweet ? <Tweet
                  pp={"greenternet.png"}
                  pseudo={"Admin Greenternet"}
                  image={urlNewTweet}
                  content={contentNewTweet}
                  likes={2340}
                  date={"Maintenant"}
                /> : ""}
              </Grid>
              {socialData.map((tweet, tweetIndex) => (
                <Grid className='postItem' maxWidth='80%!important' margin='auto' item key={tweetIndex} xs={12}>
                  {isBanned(tweet)}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item maxWidth='100%!important' flexBasis={isXsOrSm ? '100%!important' : '50%'} order={isXsOrSm ? '1' : '2'} xs={12} sm={4} md={4}>
            <Box sx={{ marginTop: '70px' }}>
              {isStepActive ? <Stepper nonLinear 
              orientation='vertical' 
              sx={{ 
                flexDirection: { xs: 'column', sm: 'column' }, 
                gap: { xs: '0.5rem', sm: '0.5rem', md: '0rem' }, 
                alignItems: { md:'initial' },
                paddingLeft: { xs: '30px', sm: '80px', md: '0' } }} >
                {steps.map((step, i) => (
                  <Step completed={completed[step.label]} key={step.label}>
                    <StepLabel>
                      {step.title} {goal[step.label].length}/{step.goal}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper> : ""}
            </Box>
            <Box sx={{ position: 'fixed', bottom: '5%', right: '10%' }}>
              <Fab color="primary" disabled={disableAddTweet} onClick={handleOpenCreate} aria-label="add">
                <AddIcon />
              </Fab>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Message setOpen={setOpenMessage} open={openMessage} changeMessage={changeMessage} numberMessage={numberMessage} />
      <div>
        <Dialog open={openConnexion} onClose={handleClose2}>
          <DialogTitle>Connexion Administrateur</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Pour vous connecter, veuillez entrer votre login et votre mot de passe.
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="name"
              label="Login"
              type="string"
              defaultValue={"Marguerite38"}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              error={iserror}
              helperText={iserror ? "Le mot de passe est incorrect" : ""}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mots de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmit}>Connexion</Button>
          </DialogActions>
        </Dialog>
      </div>
    </KnootContext.Provider>
  );
}