//Importation des bibliotheques
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Typography } from '@mui/material';

//importation des fichier js de données pour l'énigme 
import { borneDefault, borne2Default } from '../../data/hardwaredata';

//nombre de branchement
let nbCables = 0;
//nombre de bon branchement
let nbBonCables = 0;
//dernière couleur séléctionnée dans "borne du robot", 'rouge' par défaut
let lastSelect1 = 'rouge';
//dernière borne séléctionnée dans "brancher à", 'AREF' par défaut
let lastSelect2 = 'AREF';
//Etat donné aux textfield (utilisable ou non)
let disabledTextField = false;

export default function SelectTextFields(props) {

  //liste des valeurs possibles dans "borne du robot" 
  const [borne, setBorne] = React.useState(borneDefault);

  //liste des valeurs possibles dans "brancher à" 
  const [borne2, setBorne2] = React.useState(borne2Default);


  //Met à jour lastSelect1
  const fillText1 = (event) => {
    lastSelect1 = event.target.value;
  }

  //Met à jour lastSelect2
  const fillText2 = (event) => {
    lastSelect2 = event.target.value;
  }

  //tableau des bons cables, strings contenants la valeur de "borne du robot" suvie de la valeur de "brancher à"
  const bonCable = ["rouge3.3V", "noirGND", "bleu~11", "jaune~10", "vert12"];

  const handleCablage = () => {
    //variables cordonnées et couleur utilisées pour le tracé du cable
    let x1;
    let y1;
    let x2;
    let y2;
    let color;
    //variable stockant les valeurs des borne utilisées pour le branchement dans une string
    let cable;
    //regarde si le champ contient bien une valeur contenu dans les possibilités pour la borne du robot
    for (let i in borne) {
      if (document.getElementById("robot").textContent === borne[i].value) {
        //met à jour les coordonnées du premier point et la couleur pour le tracé du cable
        x1 = borne[i].x;
        y1 = borne[i].y;
        color = borne[i].value;
        //met à jour cable, prend la valeur de l'élément sélectionné
        cable = borne[i].value;
        //met à jour le tableau des possibilités
        let newBorne = [...borne];
        //si il n'est pas vide, enlève la valeur qui vient d'être selectionnée
        if (newBorne.length > 1) {
          newBorne.splice(i, 1);
          lastSelect1 = newBorne[0].value;
          setBorne(newBorne);
          //si il est vide, rend inustilisable la plateforme de branchement, toutes les bornes du robot sont déjà utilisées
        } else {
          disabledTextField = true;
        }
      }
    }
    //même fontionnement, cette fois ci pour le champ "brancher à" de la platefomre de branchement
    for (let i in borne2) {
      if (document.getElementById("tableau").textContent === borne2[i].value) {
        //met à jour les coordonnées du deuxième point pour le tracé du cable
        x2 = borne2[i].x;
        y2 = borne2[i].y;
        //ajout à cable la valeur du champ dans "borne à brancher"
        cable = cable + borne2[i].value;
        let newBorne2 = [...borne2];
        if (newBorne2.length > 1) {
          newBorne2.splice(i, 1);
          lastSelect2 = newBorne2[0].value;
          setBorne2(newBorne2);
        } else {
          disabledTextField = true;
        }
      }
    }

    //met à jour le nombre de bon cables branchés, si le cable actuel est dans bonCables, incrémentation
    nbCables++;
    if (bonCable.includes(cable)) {
      nbBonCables++;
    }

    //Si il y a 5 cable et 5 bons cables, bon branchement, bonCablage passe à true, sinon, il reste à false
    if (nbCables === 5 && nbBonCables === nbCables) {
      props.setBonCablage(true);
    }
    else {
      props.setBonCablage(false);
    }
    //exécution de handlecablage() de la classe mère
    props.handleCablage(x1, y1, x2, y2, color);

    return;
  }

  //réinitialise la plateforme de connexion (tableau de possibilité, nombre de cable branchés...), ces deux objets doivent etre identique au initialisation du haut
  function handleDecablage() {
    setBorne(borneDefault);
    setBorne2(borne2Default);
    nbCables = 0;
    nbBonCables = 0;
    props.setBonCablage(false);
    props.handleDecablage();
    disabledTextField = false;
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }, marginTop: '-70px',
      }}
      noValidate
      autoComplete="off"
    >
      <Typography>Branchement du robot</Typography>
      <Box>
        <Box display={'inline'}>
          <TextField
            id="robot"
            select
            label="Borne du robot"
            defaultValue=""
            onChange={fillText1}
            value={lastSelect1}
            disabled={disabledTextField}
          >
            {borne.map((option) => {
              return (
                <MenuItem key={option.value + "BR"} value={option.value}>
                  {option.value}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
        <Box display={'inline'}>
          <TextField
            id="tableau"
            select
            label="Brancher à"
            defaultValue=""
            onChange={fillText2}
            value={lastSelect2}
            disabled={disabledTextField}
          >
            {borne2.map((option) => {
              return (
                <MenuItem key={option.value + "BA"} value={option.value}>
                  {option.value}
                </MenuItem>
              )
            })}
          </TextField>
          <Box display={'flex'} gap={'1rem'} justifyContent={'center'} flexWrap={'wrap'} marginTop={'1rem'}>
            {/*<Button sx={{ color: "red"}} onClick={handleDecablage}>Tout débrancher</Button>*/}
            <Button sx={{ color: "white", width: "230px"}} color="error" variant='contained' onClick={handleDecablage}>Tout débrancher</Button>
            {/*<Button sx={{ color: "black" }} onClick={handleCablage} disabled={disabledTextField}>Brancher</Button>*/}
            <Button sx={{ color: "white", width: "120px"}} variant='contained' onClick={handleCablage} disabled={disabledTextField}>Brancher</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}