import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    boxShadow: 24,
    
};

export default function Commande(props) {
    
    //Ferme le modal
    const handleClose = () => {
        props.setOpen(false);
      };

    return (
        <div>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    padding:'0px',
                    overflowY: 'auto'
                }}
            >
                <Box sx={style} >
                    <img alt='commande' src="/img/network_img/commandeDeBase.png" width="100%" ></img>
                </Box>
            </Modal>
        </div>
    );
}