import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Typography, useMediaQuery } from '@mui/material';
import { connexionPossible } from '../../data/networkdata';

let path = "";

export default function SelectTextFields(props) {

  //tableau des connexions possibles depuis la machine courante
  const [currencies, setCurrencies] = React.useState([{
    value: "S1",
    label: "S1"
  },
  {
    value: "S3",
    label: "S3"
  },
  {
    value: "S9",
    label: "S9"
  }
  ]);

  //Dernière machine qui a été connecté, par défaut notrre machine : "NOUS"
  let [lastSelect, setLastSelect] = React.useState("NOUS");

  //Gère la connexion des machines, executée sur le bouton connecter
  const handleConnexion = () => {
    let tab = [];
    for (let i in connexionPossible) {
      //Si la machine fait partie des connexions possibles
      if (document.getElementById("selection").textContent === connexionPossible[i].machine) {
        for (let j in connexionPossible[i].voisins) {
          //On vérifie que l'on est pas déjà passé par ce voisin pour ne pas permettre le retour en arrière 
          if (connexionPossible[i].voisins[j] !== lastSelect) {
            tab[j] = {//Mise à jour de tab avec les nouveaux voisins
              value: connexionPossible[i].voisins[j], label: connexionPossible[i].voisins[j]
            }
          }
        }
        //Passe tab dans currencies pour mettre à jour les voisins
        setCurrencies(tab);
        //Color le lien entre les machines qui se connectent
        props.setColor(lastSelect, document.getElementById("selection").textContent); 
        //Mise à jour des varianles liées au path
        setLastSelect(document.getElementById("selection").textContent);
        path = (path + document.getElementById("selection").textContent);
        props.setGoodPath(path);
        return;
      }
    }
  };

  //Réinitialise la plateforme de connexion des machines
  const handleDeconnexion = () => {
    setCurrencies([{
      value: "S1",
      label: "S1"
    },
    {
      value: "S3",
      label: "S3"
    },
    {
      value: "S9",
      label: "S9"
    }
    ]);
    setLastSelect("NOUS");
    path = "";
    props.setGoodPath(false);
    props.reinitialiseColor();
  }

 const isMobileBreakpoint = useMediaQuery(('max-width:767px'))

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
        marginTop: '20px',
        paddingBottom: '20px',
      }}
      noValidate
      autoComplete="off"
    >
      <Typography>Connexion machines</Typography>
      <div>
        <TextField
          id="currentDevice"
          value={lastSelect}
        >
        </TextField>
        <TextField
          id="selection"
          select
          label="Connecter à"
          defaultValue=""
        >
          {currencies.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </TextField>
      </div>
        <Box marginTop='20px' >
          <Button sx={{ color: "white",  marginRight: isMobileBreakpoint ? '0px' : '20px'}} variant='contained' color='error' onClick={handleDeconnexion}>Tout déconnecter</Button>
          <Button sx={{ color: "white"}} variant='contained' onClick={handleConnexion} >Connecter</Button>
        </Box>
    </Box>
  );
}