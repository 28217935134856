import * as React from 'react';
import { Button, Card, CardContent, CardMedia, Typography, useMediaQuery, Box } from '@mui/material';
import EnigmeContext from './EContext';
import { Link, useLocation } from 'react-router-dom';

export default function Video(props) {
  const { setActivateNext, setActivatePrev, setDisablePrev } = React.useContext(EnigmeContext);
  const [showHubBtn, setShowHubBtn] = React.useState(false);
  const location = useLocation();
  const isTabletBreakPoint = useMediaQuery('(max-width: 1024px)');

  React.useEffect(() => {
    if (location.pathname === '/hardware/3' || location.pathname === '/bdd/2') {
      setShowHubBtn(true);
    } else {
      setShowHubBtn(false);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (props.prev) {
      setActivatePrev();
    } else {
      setDisablePrev();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prev]);

  return (
    <Card sx={{ maxWidth: isTabletBreakPoint ? '90%' : '50%', margin: 'auto', marginBottom: '2%', marginTop: '30px' }}>
      {showHubBtn && (
        <Box sx={{ padding: '16px', textAlign: 'center',  }}>
          <Link to="/hub" style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="primary" sx={{ background: 'linear-gradient(45deg, #6B22E4, #AB9230)'}}>Retour au hub</Button>
          </Link>
        </Box>
      )}
      <CardMedia
        sx={{ maxHeight: 500}}
        component="video"
        role='video'
        controls
        image={props.url}
        onPlay={props.next ? setActivateNext : null}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.transcription}
        </Typography>
      </CardContent>
    </Card>
  );
}