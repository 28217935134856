import * as React from "react";
import { Box, Typography } from '@mui/material';
import { Button, Stack } from "@mui/material";

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AddIcon from '@mui/icons-material/Add';

import Cookies from "js-cookie";

import { useNavigate } from 'react-router-dom';

export default function Admin(props) {
    const [isPaused, setPaused] = React.useState(false);
    const navigate = useNavigate();

    //Récupère et enlève tout les cookies ainsi que le localStorage
    const reset = () => {
        clearInterval(props.intervalID);
        Object.keys(Cookies.get()).forEach(function (cookieName) {
            var neededAttributes = {
                path: '/',
            };
            Cookies.remove(cookieName, neededAttributes);
        });
        navigate('/');
        Cookies.set('EndGame', 0, { path: '/' });
        localStorage.clear();

        window.location.reload();
    }

    //Mets en pause en enlevant l'interval
    const pause = () => {
        setPaused(true);
        clearInterval(props.intervalID);
    }

    //Se déconnecte en tant qu'admin
    const singOut = () => {
        Cookies.remove("authadmintoken", { path: "/" })
    }

    //Permet de remettre le compteur en marche
    const play = () => {
        setPaused(false);
        window.location.reload();
    }

    const addCredit = () => {
        const add = parseInt(Cookies.get("hintcreditUse")) - 1;
        Cookies.set("hintcreditUse", add, { path: "/" });
    }

    return (
        <Box sx={{padding: '20px'}}>
            <Stack sx={{ margin: "auto", justifyContent: "center", flexWrap: 'wrap', gap: '1rem' }} direction="row" spacing={2}>
                <Button
                    sx={{
                        display: 'flex',
                        background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                    }}
                    data-testid="addcredit-button"
                    variant="contained"
                    size="large"
                    startIcon={<AddIcon />}
                    onClick={addCredit}
                >
                    1 crédit d'indice
                </Button>
                {isPaused ?
                    <Button
                        sx={{
                            display: 'flex',
                            background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                        }}
                        variant="contained"
                        data-testid="play-button"
                        size="large"
                        startIcon={<PlayArrowIcon />}
                        onClick={play}
                    >
                        Play
                    </Button>
                    :
                    <Button
                        sx={{
                            display: 'flex',
                            background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                        }}
                        data-testid="pause-button"
                        variant="contained"
                        size="large"
                        startIcon={<PauseIcon />}
                        onClick={pause}
                    >
                        Pause
                    </Button>

                }
                <Button
                    sx={{
                        display: 'flex',
                        background: 'linear-gradient(45deg, #6B22E4, #AB9230)',
                    }}
                    data-testid="reset-button"
                    variant="contained"
                    size="large"
                    startIcon={<RestartAltIcon />}
                    onClick={reset}
                >
                    Reset
                </Button>
                <Button
                    sx={{
                        display: 'flex',
                        backgroundColor: 'red',
                    }}
                    data-testid="deconect-button"
                    variant="contained"
                    size="large"
                    startIcon={<ExitToAppIcon />}
                    onClick={singOut}
                >
                    Se déconnecter
                </Button>
            </Stack>
            <Box textAlign={'center'} sx={{ mt: '15px' }}>
                <Typography variant="h7" color="gray">Prototype Parallaxe 2050 developped by: L.ROUBY R.JANSON R.POMMATAU</Typography>
            </Box>
        </Box>
    )
}

