//tableau du premier terminal, dialogue avec Dora
//text -> text de Dora, answerAccepted -> tableau des commandes acceptées en retour
export const reponseTerminal = [{
    text: "Vous êtes là ? Il y a quelqu'un.e ? Vous me recevez ? Si vous pouvez lire ce message, tapez “yes” !", answerAccepted: ["YES", "yes", "Yes", "YEs", "YeS", "yeS", "yES", "yEs"]
},
{
    text: "Enfin quelqu'une ! On a un gros problème qui m'empêche de vous contacter autrement qu'ici. J'ai besoin de votre aide pour effectuer une opération depuis mon bureau. Êtes-vous prêt·es à m'aider ?", answerAccepted: ["YES", "yes", "Yes", "YEs", "YeS", "yeS", "yES", "yEs", "oui", "ouI", "oUi", "oUI", "Oui", "OuI", "OUi", "OUI"]
},
{
    text: "Super. Alors, le virus a infecté beaucoup de serveurs du réseau du Greenternet. Il va nous falloir rétablir la connexion jusqu'à l'un de nos alliés sans passer par les serveurs infectés. Depuis ce terminal vous allez pouvoir identifier l'état des serveurs pour éventuellement les contourner. Mes documents expliquent comment faire. Cliquez dessus pour les examiner. Une fois que vous avez un allié accessible, envoyez-lui un PING. Nous pourrons alors l'utiliser pour propager un antivirus sur le réseau. Je compte sur vous ! Tapez ok pour pouvoir connecter les machines.", answerAccepted: ["ok", "OK", "Ok", "oK"]
}
];

//tableau du deuxième terminal (ping et status)
//commande -> commandes possibles donnant une réponse, réponse -> réponse de la commande tapée
export const reponseTerminal2 = [
    {
        commande: "PING 52.59.222.97", reponse: "Hahaha ! Tu es en train de pinguer ta propre adresse locale !"
    },
    {
        commande: "PING 46.229.161.131", reponse: "11 paquets transmis, 11 paquets reçus, 0% des paquets perdus"
    },
    {
        commande: "PING 194.71.211.50", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 111.206.198.123", reponse: "11 paquets transmis, 11 paquets reçus, 0% des paquets perdus"
    },
    {
        commande: "PING 192.71.18.47", reponse: "11 paquets transmis, 11 paquets reçus, 0% des paquets perdus"
    },
    {
        commande: "PING 91.121.180.203", reponse: "11 paquets transmis, 11 paquets reçus, 0% des paquets perdus"
    },
    {
        commande: "PING 105.112.100.226", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 5.182.210.16", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 79.152.164.28", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 167.88.60.250", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 31.13.127.11", reponse: "11 paquets transmis, 11 paquets reçus, 0% des paquets perdus"
    },
    {
        commande: "PING 34.76.60.69", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "PING 111.206.198.31", reponse: "11 paquets transmis, 0 paquets reçus, 100.0% des paquets perdus"
    },
    {
        commande: "STATUS 46.229.161.131", reponse: "ok"
    },
    {
        commande: "STATUS 194.71.211.50", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 111.206.198.123", reponse: "ok"
    },
    {
        commande: "STATUS 192.71.18.47", reponse: "ok"
    },
    {
        commande: "STATUS 91.121.180.203", reponse: "ok"
    },
    {
        commande: "STATUS 105.112.100.226", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 5.182.210.16", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 79.152.164.28", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 167.88.60.250", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 31.13.127.11", reponse: "attention, serveur infecté"
    },
    {
        commande: "STATUS 34.76.60.69", reponse: "ok"
    },
    {
        commande: "STATUS 111.206.198.31", reponse: "ok"
    },
    {
        commande: "STATUS 52.59.222.97", reponse: "C'est nous!"
    },
    {
        commande: "HELP", reponse: "Il faut trouver une route pour communiquer avec les allié.es. Attention, certains serveurs sont infectés par le virus et ne transmettent pas de message. Utilisez la commande Status pour savoir si un serveur est sain ou non. Lorsque que vous aurez trouvé comment établir la connexion jusqu'aux alliés utilisez la commande Ping."
    },
];

//tableau associant chaque machine à ses voisins dans le schéma
//machine -> machine courante, voisins -> tableau des voisins de la machine
export const connexionPossible = [{
    machine: "NOUS", voisins: ['S1', 'S3', 'S9']
},
{
    machine: "S1", voisins: ['NOUS', 'S5', 'S6', 'S9']
},
{
    machine: "S2", voisins: ['ALL-1', 'ALL-2', 'S7']
},
{
    machine: "S3", voisins: ['NOUS', 'S4']
},
{
    machine: "S4", voisins: ['ALL-1', 'S3', 'S6']
},
{
    machine: "S5", voisins: ['S1', 'S7', 'S10']
},
{
    machine: "S6", voisins: ['ALL-1', 'S1', 'S4', 'S8']
},
{
    machine: "S7", voisins: ['S2', 'S5', 'S8', 'S9', 'S10']
},
{
    machine: "S8", voisins: ['ALL-2', 'S6', 'S7']
},
{
    machine: "S9", voisins: ['NOUS', 'S1', 'S7']
},
{
    machine: "S10", voisins: ['ALL-2', 'S5', 'S7']
},
{
    machine: "ALL-1", voisins: []
},
{
    machine: "ALL-2", voisins: []
}
];