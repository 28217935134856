import { styled } from "@mui/material";

function RequiredMentions() {
  //CSS image des partenaires
  const ImageSrcPartenaire = styled("span")(({ theme }) => ({
    display: "block",
    width: "100%",
    height: "100px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "10px",
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
  }));
  return (
    <div className="RequiredMentionsWrapper">
      <div className="RequiredMentions">
      Projet porté par le Campus des Métiers et des Qualifications Informatique et Electronique de Demain, en partenariat avec l'Université Grenoble Alpes (UGA)
      </div>
      <div className="RequiredMention">
      Projet subventionné par la Région Auvergne-Rhône-Alpes à hauteur de 3500€
      </div>
      <ImageSrcPartenaire
        style={{ backgroundImage: "url(/img/logo_partenaires_couleurs.png)" }}
      />
    </div>
  );
}

export default RequiredMentions;
