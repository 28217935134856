//Importation des bibliotheques
import * as React from 'react';
import { Box, Typography } from '@mui/material';
import EnigmeContext from '../EContext';

//Fonction de rendu du composant
export default function Robot() {

  let width = 256 * (window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 2 : 1) + 'px'; // *3 on desktop, *2 on tablet, *1 on mobile
  let height = 141 * (window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 2 : 1) + 'px'; // *3 on desktop, *2 on tablet, *1 on mobile

  const { setActivateNext, setDisableAll } = React.useContext(EnigmeContext)//Importation de la fonction pour les boutons Suivant et Précédent

  React.useEffect(() => {
    setDisableAll();//Desactiver par défaut les boutons Suivant et Précédents
    setActivateNext();//Activer le boutons Suivant par défaut
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant='h5' marginTop={'20px'} textAlign={'center'}>Le bras robot s’active, il trace un caractère !</Typography>
      <Box sx={{padding: '16px', margin: '30px 0px', backgroundImage: 'url(/img/hardware_img/gif_robot.gif)' }} width={width} height={height} />
    </Box>
  )
}
