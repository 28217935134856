import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CheckBox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import KnootContext from './KContext';

import { uploadDataImg } from '../../data/socialdata';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    // CSS du tweet
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CreateTweet(props) {
    const [imgCurrent, setImgCurrent] = React.useState(""); // Image du tweet
    const [message, setMessage] = React.useState(); //Message du tweet
    const [checked, setCheck] = React.useState(false); // GCV du tweet
    const [isErrorImage, setErrorImage] = React.useState(false); // Si il n'y a pas d'images
    const [isErrorText, setErrorMessage] = React.useState(false); //Si il n'y a pas de text

    // function de contexte pour savoir si c'est une bonne image
    const { checkUpload } = React.useContext(KnootContext);


    const handleClose = () => {
        props.setOpen(false);
    };

    const handleClickCheckBox = () => {
        if (checked) {
            setCheck(false);
        } else {
            setCheck(true);
        }
    };

    const checkBeforeUpload = () => {
        //Si tout est rempli
        if (imgCurrent && message && checked) {
            checkUpload(imgCurrent, message);
            handleClose();
        }
        else if (!imgCurrent) {
            setErrorImage(true) 
        } 
        else if (!message) {
            setErrorMessage(true);
        }
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                fullWidth
                maxWidth="md"
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Ajouter un Knoot
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Grid direction="row" container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Stack spacing={2} direction="column">
                                <FormControl fullWidth>
                                    <InputLabel>Selection Image</InputLabel>
                                    <Select
                                        value={imgCurrent}
                                        error={isErrorImage}
                                        label="Upload Image"
                                        onChange={(event) => {
                                            setErrorImage(false);
                                            setImgCurrent(event.target.value);
                                        }}
                                    >
                                        {
                                            uploadDataImg.map((image, index) => (
                                                <MenuItem key={index} value={image.url}>{image.url} - {image.size}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    variant='outlined'
                                    error={isErrorText}
                                    size='small'
                                    placeholder="Message..."
                                    value={message}
                                    onChange={(event) => {
                                        setErrorMessage(false);
                                        setMessage(event.target.value);
                                    }}
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card sx={{ maxWidth: 400, margin: 'auto' }}>
                                <CardHeader
                                    avatar={
                                        <Avatar src="/img/tweet_img/greenternet.png" alt="logo greenternet"/>
                                    }
                                    title="Admin Greenternet"
                                    subheader="Maintenant"
                                />
                                {imgCurrent ? <CardMedia
                                    component="img"
                                    image={`/img/tweet_img/${imgCurrent}`}
                                    alt={imgCurrent}
                                /> : ""}
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        {message}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        marginTop={3}
                        direction="row"
                    >
                        <Grid item>
                            <Typography sx={checked ? {} : {color: "red"}} variant="caption">
                                J'autorise Greenternet à utiliser mes données à des fin de publication conformément à la RGPD en vigueur*
                            </Typography>
                            <CheckBox checked={checked} onChange={handleClickCheckBox} size='small' />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={checkBeforeUpload}>
                        Uploader !
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}